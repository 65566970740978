import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Box, Button, Stack } from '@mui/material'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import { GridFilterItem } from '@mui/x-data-grid-premium'
import { GridLogicOperator } from '@mui/x-data-grid/models/gridFilterItem'
import * as React from 'react'
import { useState } from 'react'
import { ObsField } from '../../model/analysisCommands'
import ClearAllFiltersDialog from './ClearAllFiltersDialog'
import CreateOrUpdateFilterItemDialog from './CreateOrUpdateFilterItemDialog'
import FilterItem from './FilterItem'

export interface FilterManagementAreaParams {
    fields: ObsField[]
    filterItems: GridFilterItem[]
    setFilterItems: React.Dispatch<GridFilterItem[]>
    logicalOperator: GridLogicOperator
    setLogicalOperator: React.Dispatch<GridLogicOperator>
    inOperatorButtons?: boolean
    sx?: SxProps
}

export default function FilterManagementArea({
    fields,
    filterItems,
    setFilterItems,
    logicalOperator,
    setLogicalOperator,
    inOperatorButtons,
    sx,
}: FilterManagementAreaParams) {
    const [openCreateOrUpdateFilterItemDialog, setOpenCreateOrUpdateFilterItemDialog] = useState(false)
    const [openClearAllFilterItemsDialog, setOpenClearAllFilterItemsDialog] = useState(false)
    const [editingFilterItem, setEditingFilterItem] = useState<GridFilterItem | null>(null)

    const setFilterItem = (oldItem: GridFilterItem | null, newItem: GridFilterItem) => {
        const newFilterItemList = Array.from(filterItems)
        if (oldItem) {
            const currentFilterItemIndex = newFilterItemList.findIndex(
                (fi) => fi.field == oldItem.field && fi.operator == oldItem.operator && fi.value == oldItem.value,
            )
            if (currentFilterItemIndex != -1) {
                newFilterItemList[currentFilterItemIndex] = newItem
            }
        } else {
            newFilterItemList.push(newItem)
        }
        setFilterItems(newFilterItemList)
    }

    const deleteFilterItem = (item: GridFilterItem) => {
        const newFilterItemList = filterItems.filter(
            (fi) => !(fi.field == item.field && fi.operator == item.operator && fi.value == item.value),
        )
        setFilterItems(newFilterItemList)
    }

    const toggleLogicalOperator = () => {
        if (logicalOperator == GridLogicOperator.And) {
            setLogicalOperator(GridLogicOperator.Or)
        } else {
            setLogicalOperator(GridLogicOperator.And)
        }
    }

    return (
        <>
            <Stack sx={sx}>
                <Box>
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ mr: 1 }}
                        variant={'outlined'}
                        onClick={() => {
                            setOpenCreateOrUpdateFilterItemDialog(true)
                        }}
                    >
                        Add Filter
                    </Button>
                    <Button
                        startIcon={<ClearAllIcon />}
                        variant={'outlined'}
                        disabled={filterItems?.length == 0}
                        onClick={() => {
                            setOpenClearAllFilterItemsDialog(true)
                        }}
                    >
                        Clear All Filters
                    </Button>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            color: '#287473',
                            pl: 1,
                            pr: 1,
                            mr: 1,
                            border: '1px solid transparent',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                            '&:hover': {
                                border: '1px solid #287473',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={toggleLogicalOperator}
                    >
                        {logicalOperator.toUpperCase()}
                    </Box>
                    <Divider
                        orientation='vertical'
                        flexItem
                        sx={{ mr: 2, backgroundColor: '#264653', opacity: '0.5' }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                        {filterItems.length > 0 ? (
                            <>
                                {filterItems.map((item, idx) => (
                                    <FilterItem
                                        key={`filter-${idx}`}
                                        item={item}
                                        onEdit={() => {
                                            setEditingFilterItem(item)
                                            setOpenCreateOrUpdateFilterItemDialog(true)
                                        }}
                                        onDelete={() => {
                                            deleteFilterItem(item)
                                        }}
                                    />
                                ))}
                            </>
                        ) : (
                            <Typography
                                variant={'body2'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#666',
                                }}
                            >
                                <FilterAltIcon /> No filters added
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Stack>
            {fields && (
                <CreateOrUpdateFilterItemDialog
                    item={editingFilterItem}
                    fields={fields}
                    onSubmit={(oldItem, newItem) => {
                        setFilterItem(oldItem, newItem)
                        setEditingFilterItem(null)
                    }}
                    openDialog={openCreateOrUpdateFilterItemDialog}
                    handleCloseDialog={() => {
                        setOpenCreateOrUpdateFilterItemDialog(false)
                        setEditingFilterItem(null)
                    }}
                    inOperatorButtons={inOperatorButtons}
                />
            )}
            <ClearAllFiltersDialog
                openDialog={openClearAllFilterItemsDialog}
                handleCloseDialog={() => {
                    setOpenClearAllFilterItemsDialog(false)
                }}
                clearFilters={() => {
                    setFilterItems([])
                }}
            />
        </>
    )
}
