import { Box, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'

export default function SequentialLoader({ steps, timeBetweenSteps }: { steps: string[]; timeBetweenSteps: number }) {
    const [completedSteps, setCompletedSteps] = useState<number[]>([])

    useEffect(() => {
        steps.forEach((_, index) => {
            if (index === steps.length - 1) return // Don't complete the last step
            setTimeout(
                () => {
                    setCompletedSteps((prev) => [...prev, index])
                },
                (index + 1) * timeBetweenSteps,
            )
        })
    }, [])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {steps.map((step, index) => (
                <Box
                    key={step}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    {completedSteps.includes(index) ? <CheckIcon color='success' /> : <CircularProgress size={20} />}
                    <Typography variant='h6'>{step}</Typography>
                </Box>
            ))}
        </Box>
    )
}
