import { Box, Checkbox, CircularProgress, FormControlLabel, Link, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import '../../../extensions/number.ext'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { DialogProps, emptyPermissionSet, PermissionSet, Role } from '../../../model/model'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import { receivedRole, selectRoleById } from './adminRoleSlice'
import { useCreateRoleMutation, useUpdateRoleMutation } from './roleApiSlice'

export interface CreateOrUpdateRoleParams extends DialogProps {
    roleId?: number | null
}

export default function CreateOrUpdateRoleDialog({ openDialog, handleCloseDialog, roleId }: CreateOrUpdateRoleParams) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [permissions, setPermissions] = useState<PermissionSet>(emptyPermissionSet)
    const role = useAppSelector<Role | undefined>((state) => {
        if (roleId) {
            return selectRoleById(state, roleId)
        }
        return undefined
    })
    const [createRoleApi, { isLoading: isCreating }] = useCreateRoleMutation()
    const [updateRoleApi, { isLoading: isUpdating }] = useUpdateRoleMutation()
    const [toggleVal, setToggleVal] = useState(true)

    useEffect(() => {
        if (openDialog) {
            resetFormValues()
        }
        if (!role) {
            return
        }
        setName(role.name)
        setPermissions({
            manageUsers: role.manageUsers,
            manageRoles: role.manageRoles,
            manageMetadata: role.manageMetadata,
            manageGuests: role.manageGuests,
            manageOrgSettings: role.manageOrgSettings,
            manageGeneSetCollections: role.manageGeneSetCollections,
            manageCellAtlases: role.manageCellAtlases,
            editProfile: role.editProfile,
            manageApiKeys: role.manageApiKeys,
            viewBillingInfo: role.viewBillingInfo,
            updateOrgBillingAccount: role.updateOrgBillingAccount,
            addCreditsToOrg: role.addCreditsToOrg,
            useOrgBillingAccount: role.useOrgBillingAccount,
            importSamples: role.importSamples,
            editSampleMetadata: role.editSampleMetadata,
            manageProjectSamples: role.manageProjectSamples,
            implicitAdminOnProjects: role.implicitAdminOnProjects,
            discoverProjects: role.discoverProjects,
            createProjects: role.createProjects,
            runAnalyses: role.runAnalyses,
            publishAnalyses: role.publishAnalyses,
            downloadDatasets: role.downloadDatasets,
            manageAnyGeneList: role.manageAnyGeneList,
            browseSamples: role.browseSamples,
            useOmicsBrowser: role.useOmicsBrowser,
            manageCollections: role.manageCollections,
            requestGeneDetails: role.requestGeneDetails,
        } as PermissionSet)
    }, [role, openDialog])

    const resetFormValues = () => {
        setName('')
        setPermissions(emptyPermissionSet)
    }

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handlePermissionChange = (event: React.ChangeEvent<HTMLInputElement>, permission: string) => {
        const newPermissions = JSON.parse(JSON.stringify(permissions))
        newPermissions[permission] = event.target.checked
        setPermissions(newPermissions)
    }

    const toggleAllPermissions = () => {
        const newPermissions = JSON.parse(JSON.stringify(permissions))
        Object.keys(permissions).forEach((p) => {
            newPermissions[p] = toggleVal
        })
        setPermissions(newPermissions)
        setToggleVal(!toggleVal)
    }

    const resetErrors = () => {
        setNameError(false)
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!name) {
            setNameError(true)
            valid = false
        }
        return valid
    }

    const submit = async () => {
        if (!validateForm()) {
            return
        }
        if (role && roleId) {
            const newRole = await updateRoleApi({
                id: roleId,
                name: name,
                ...permissions,
            }).unwrap()
            dispatch(
                receivedRole({
                    role: newRole,
                }),
            )
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: 'The role was successfully updated.',
                }),
            )
        } else {
            const newRole = await createRoleApi({
                name: name,
                ...permissions,
            }).unwrap()
            dispatch(
                receivedRole({
                    role: newRole,
                }),
            )
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: 'The role was successfully created.',
                }),
            )
        }
        close()
    }

    useSubmitOnEnter(submit, openDialog)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        resetErrors()
    })

    return (
        <Dialog open={openDialog} onClose={close} maxWidth={'md'}>
            <DialogTitle>{!roleId ? 'Create Role' : 'Edit Role'}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '600px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <TextField
                            onChange={handleNameChange}
                            value={name}
                            label={t('name')}
                            error={nameError}
                            slotProps={{
                                htmlInput: {
                                    autoComplete: 'off', // disable autocomplete and autofill
                                },
                            }}
                            required
                            fullWidth
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <Typography variant={'h6'}>Permissions</Typography>
                            <Link onClick={toggleAllPermissions}>{toggleVal ? 'Select all' : 'Unselect all'}</Link>
                        </Box>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                            {Object.entries(permissions)
                                .sort((a, b) => {
                                    if (a[0] < b[0]) {
                                        return -1
                                    }
                                    if (a[0] > b[0]) {
                                        return 1
                                    }
                                    return 0
                                })
                                .map((e, i) => {
                                    return (
                                        <FormControlLabel
                                            sx={{ width: 'calc(50% - 8px)' }}
                                            control={
                                                <Checkbox
                                                    value={e[1]}
                                                    checked={e[1]}
                                                    onChange={(event) => {
                                                        handlePermissionChange(event, e[0])
                                                    }}
                                                />
                                            }
                                            label={t(e[0])}
                                            key={`permission-${e[0]}-${i}`}
                                        />
                                    )
                                })}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isCreating && !isUpdating ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
