import InfoIcon from '@mui/icons-material/Info'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, Button, Divider, FormControlLabel, IconButton, Stack, Switch, Tooltip } from '@mui/material'
import { SxProps } from '@mui/system'
import { useState } from 'react'
import { useAppDispatch } from '../../../../app/hooks'
import InlineEditableProp from '../../../../components/InlineEditableProp'
import { Component } from '../../../../model/analysisComponents'
import { AnalysisWorkflow } from '../../../../model/model'
import ConfirmationDialog from '../../../common/ConfirmationDialog'
import { receivedAppMessage } from '../../../dashboard/appMessageSlice'
import {
    useDeleteComputationResultMutation,
    useRenameComputationResultMutation,
} from '../results/analysisResultsApiSlice'
import { deleteComputationResult, renameComputationResult } from '../results/analysisResultsSlice'
import CommandParamsUsedDialog from './CommandParamsUsedDialog'
import { useTranslation } from 'react-i18next'
type ResultKeyTitleProps = {
    analysisId: number
    analysisWorkflow: AnalysisWorkflow
    execCommandId: number
    resultKey: string
    toOverview: () => void
    name: string
    component: Component
    sx?: SxProps
    onHideUnannotated?: (value: boolean) => void
}

export default function ResultKeyTitle(props: ResultKeyTitleProps) {
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const [renameRequest] = useRenameComputationResultMutation()
    const [deleteRequest, { isLoading: isDeleting }] = useDeleteComputationResultMutation()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [commandResultInfoDialogOpen, setCommandResultInfoDialogOpen] = useState(false)

    const validate = (value: string) => {
        if (value.includes('\t')) {
            dispatch(
                receivedAppMessage({
                    type: 'error',
                    message: 'Result name must not contain tabs.',
                }),
            )
            return false
        }

        return true
    }

    const onSave = async (value: string) => {
        if (!validate) {
            return
        }

        await renameRequest({
            analysisId: props.analysisId,
            key: props.resultKey,
            name: value,
            component: props.component,
        }).unwrap()

        dispatch(
            renameComputationResult({
                analysisId: props.analysisId,
                key: props.resultKey,
                name: value,
                component: props.component,
            }),
        )

        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'Your changes were saved successfully.',
            }),
        )
    }

    const onDelete = async () => {
        await deleteRequest({
            analysisId: props.analysisId,
            key: props.resultKey,
            component: props.component,
        }).unwrap()

        dispatch(
            deleteComputationResult({
                analysisId: props.analysisId,
                key: props.resultKey,
                component: props.component,
            }),
        )

        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'Your result was deleted successfully.',
            }),
        )

        setDeleteDialogOpen(false)
        props.toOverview()
    }

    return (
        <Box sx={props.sx}>
            <Stack direction={'row'} justifyContent={'space-between'} justifyItems={'center'}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        sx={{ mr: 2 }}
                        variant={'outlined'}
                        startIcon={<KeyboardBackspaceIcon />}
                        onClick={props.toOverview}
                    >
                        back
                    </Button>
                    <InlineEditableProp
                        value={props.name}
                        save={onSave}
                        notEmpty
                        sx={{ '.MuiTextField-root': { width: '300px' } }}
                        truncateAt={50}
                    />
                    <Tooltip title={'Click to see info'} arrow>
                        <IconButton
                            size={'small'}
                            onClick={() => {
                                setCommandResultInfoDialogOpen(true)
                            }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                    {props.onHideUnannotated && (
                        <>
                            <Divider orientation='vertical' sx={{ mx: 1, height: 30 }} />
                            <Tooltip
                                title={'Applies to Gm and Rik genes for mouse and rat and to LOC genes for human.'}
                                arrow
                            >
                                <FormControlLabel
                                    sx={{ '.MuiTypography-root': { fontSize: '12px', fontWeight: '500' } }}
                                    control={
                                        <Switch
                                            onChange={(e) => props.onHideUnannotated!(e.target.checked)}
                                            name={t('hideUnannotated')}
                                            defaultChecked
                                        />
                                    }
                                    label={t('hideUnannotated')}
                                />
                            </Tooltip>
                        </>
                    )}
                </Box>
                <Button sx={{ m: 1 }} variant={'outlined'} color='error' onClick={() => setDeleteDialogOpen(true)}>
                    Delete Result
                </Button>
            </Stack>
            <CommandParamsUsedDialog
                openDialog={commandResultInfoDialogOpen}
                handleCloseDialog={() => {
                    setCommandResultInfoDialogOpen(false)
                }}
                commandId={props.execCommandId}
            />
            <ConfirmationDialog
                title='Delete Confirmation'
                content='Are you sure you want to permanently delete this result?'
                confirmButtonLabel='Delete'
                cancelButtonLabel='Cancel'
                openDialog={deleteDialogOpen}
                handleCloseDialog={() => {
                    setDeleteDialogOpen(false)
                }}
                handleConfirm={onDelete}
                formSubmitted={isDeleting}
            />
        </Box>
    )
}
