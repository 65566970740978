import { getContrastColor } from '../utils/color_palette'

import { SxProps } from '@mui/material'
import { Chip } from '@mui/material'
import { stringToColor } from '../utils/color_palette'

export default function ColoredChip({
    label,
    size = 'small',
    sx,
}: {
    label: string
    size?: 'small' | 'medium'
    sx?: SxProps
}) {
    const bgColor = stringToColor(label)
    const textColor = getContrastColor(bgColor)

    return (
        <Chip
            key={label}
            label={label}
            style={{
                backgroundColor: bgColor,
                color: textColor,
            }}
            size={size}
            sx={sx}
        />
    )
}
