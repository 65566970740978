import CheckIcon from '@mui/icons-material/Check'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'
import { useAppSelector } from '../../../../../../app/hooks'
import { ComputeStatisticsPreview, ObsField } from '../../../../../../model/analysisCommands'
import { selectAnalysisId, selectAnalysisWorkflow } from '../../../analysisSlice'
import ResultsOverview from '../../../common/ResultsOverview'
import ComputeStatisticsOverviewResultTile from './ComputeStatisticsOverviewResultTile'
import ComputeStatisticsComponentResultDetails from './ComputeStatisticsComponentResultDetails'
import ComputeStatisticsComponentForm from './ComputeStatisticsComponentForm'
import { ComputeStatistics } from '../../../../../../model/analysisComponents'
import { useState } from 'react'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

export interface ComputeStatisticsComponentParams {
    computeStatisticsPreviews: Record<string, ComputeStatisticsPreview> | undefined
    isFetchingResults: boolean
    obsColumnMapping: Record<string, Record<string, string>> | undefined
    obsFields: ObsField[] | undefined
    geneSet: Set<string> | undefined
}

export default function ComputeStatisticsComponent({
    computeStatisticsPreviews,
    isFetchingResults,
    obsColumnMapping,
    obsFields,
    geneSet,
}: ComputeStatisticsComponentParams) {
    const { t } = useTranslation()
    const analysisId = useAppSelector(selectAnalysisId)
    const analysisWorkflow = useAppSelector(selectAnalysisWorkflow)
    const { width = 0 } = useWindowSize()
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedKey = searchParams.get('key')

    function setKey(key: string | null) {
        searchParams.delete('key')
        if (key) {
            searchParams.set('key', key)
        }
        setSearchParams(searchParams)
    }

    return (
        <>
            {!obsColumnMapping ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'calc(100vh - 60px)',
                    }}
                >
                    <Stack
                        sx={{
                            '& .MuiBox-root': {
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2,
                            },
                            '& .MuiBox-root .MuiTypography-root': {
                                ml: 1,
                            },
                        }}
                    >
                        <Box>
                            {obsColumnMapping ? <CheckIcon color='primary' /> : <CircularProgress size={20} />}
                            <Typography>Loading data</Typography>
                        </Box>
                    </Stack>
                </Box>
            ) : (
                <Box>
                    <Box sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 60px)', overflow: 'auto' }}>
                        <Box
                            sx={{
                                borderRight: '1px solid #DDD',
                                width: 'calc(100% - 350px)',
                                height: 'calc(100vh - 60px)',
                                overflow: 'auto',
                            }}
                        >
                            {analysisId &&
                            analysisWorkflow &&
                            selectedKey &&
                            computeStatisticsPreviews &&
                            computeStatisticsPreviews[selectedKey] ? (
                                <ComputeStatisticsComponentResultDetails
                                    preview={computeStatisticsPreviews[selectedKey]}
                                    selectedKey={selectedKey}
                                    setKey={setKey}
                                    width={width}
                                />
                            ) : (
                                <>
                                    <ResultsOverview
                                        component={ComputeStatistics}
                                        resultsKey='ComputeStatisticsComponent'
                                        results={computeStatisticsPreviews}
                                        selectionModel={selectionModel}
                                        setSelectionModel={setSelectionModel}
                                        isFetchingResults={isFetchingResults}
                                        renderTile={(k) => {
                                            return (
                                                computeStatisticsPreviews && (
                                                    <ComputeStatisticsOverviewResultTile
                                                        result={computeStatisticsPreviews[k]}
                                                        resultKey={k}
                                                        onClick={setKey}
                                                        width={width - 520}
                                                    />
                                                )
                                            )
                                        }}
                                        quickSearchFilter={(keyword, k) => {
                                            if (!computeStatisticsPreviews) {
                                                return false
                                            }
                                            return computeStatisticsPreviews[k].name
                                                .toLowerCase()
                                                .includes(keyword.trim().toLowerCase())
                                        }}
                                        bgColor={'#FAFAFA'}
                                        gridColumnDefinitions={[
                                            { field: 'name', headerName: t('name'), groupable: false, width: 300 },
                                            {
                                                field: 'v1',
                                                headerName: 'Variable 1',
                                                groupable: false,
                                                width: 200,
                                            },
                                            {
                                                field: 'v2',
                                                headerName: 'Variable 2',
                                                groupable: false,
                                                width: 200,
                                            },
                                            {
                                                field: 'color',
                                                headerName: 'Color',
                                                groupable: false,
                                                width: 200,
                                            },
                                        ]}
                                        renderRow={(k) => {
                                            if (!computeStatisticsPreviews) {
                                                return {}
                                            }
                                            const result = computeStatisticsPreviews[k]
                                            return {
                                                id: k,
                                                name: result?.name,
                                                v1: result.v1,
                                                v2: result.v2,
                                                color: result.color,
                                            }
                                        }}
                                        onRowClick={(params) => {
                                            setKey(params.id as string)
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                width: '350px',
                                p: 2,
                                pt: 3,
                            }}
                        >
                            <ComputeStatisticsComponentForm
                                obsColumnMapping={obsColumnMapping}
                                obsFields={obsFields}
                                geneSet={geneSet}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    )
}
