import { wsSlice } from '../../app/wsSlice'
import {
    BulkExpOmicsPrepareAnalysis,
    ScRnaSeqPrepareAnalysis,
    ScRnaSeqPrepareAnalysisFromAsset,
} from '../../model/analysisComponents'
import { DoneCommand, FailedAnalysis, InProgressAnalysis } from '../../model/model'
import { receivedAnalysisStatusUpdate } from './workbenchSlice'

wsSlice.injectSelectHandlers(
    (m) => m.analysisCommandFinished,
    [
        (p, dispatch) => {
            switch (p.component) {
                case BulkExpOmicsPrepareAnalysis:
                case ScRnaSeqPrepareAnalysis:
                case ScRnaSeqPrepareAnalysisFromAsset:
                    dispatch(
                        receivedAnalysisStatusUpdate({
                            analysisIdList: [p.analysisId],
                            status: p.status == DoneCommand ? InProgressAnalysis : FailedAnalysis,
                        }),
                    )
                    break
            }
        },
    ],
)
