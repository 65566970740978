import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Tooltip } from '@mui/material'
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    ToolbarPropsOverrides,
} from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import JoinLeftIcon from '@mui/icons-material/JoinLeft'

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        resultsOverviewDeleteDisabled: boolean
        resultsOverviewDeleteCallback: () => void
        exportFileName: string
        overlapCallback: () => void
        disableOverlap: boolean
        showOverlap?: boolean
    }
}

export default function ResultsOverviewGridToolbar({
    resultsOverviewDeleteDisabled,
    resultsOverviewDeleteCallback,
    exportFileName,
    overlapCallback,
    disableOverlap,
    showOverlap,
}: ToolbarPropsOverrides) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
                csvOptions={{
                    fileName: exportFileName,
                }}
                excelOptions={{
                    fileName: exportFileName,
                }}
                printOptions={{
                    disableToolbarButton: true,
                }}
            />
            {showOverlap && <OverlapButton onClick={overlapCallback} disabled={disableOverlap} />}
            <Divider orientation={'vertical'} flexItem />
            <DeleteButton disabled={resultsOverviewDeleteDisabled} onClick={resultsOverviewDeleteCallback} />
        </GridToolbarContainer>
    )
}

type ToolbarButtonProps = {
    disabled: boolean
    onClick: () => void
}

export function DeleteButton({ disabled, onClick }: ToolbarButtonProps) {
    const { t } = useTranslation()
    return (
        <Tooltip title={'Permanently delete the selected results.'} arrow>
            <span>
                <Button startIcon={<DeleteIcon />} onClick={onClick} size={'small'} disabled={disabled} color={'error'}>
                    {t('delete')}
                </Button>
            </span>
        </Tooltip>
    )
}

export function OverlapButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Show gene overlap between the selected results.'} arrow>
            <span>
                <Button startIcon={<JoinLeftIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('viewOverlap')}
                </Button>
            </span>
        </Tooltip>
    )
}
