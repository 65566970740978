import { Box, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import React from 'react'
import { GridFilterItem } from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'
import { SxProps } from '@mui/system'

interface TileFilterItemParams {
    item: GridFilterItem
    onEdit?: () => void
    onDelete?: () => void
    sx?: SxProps
}

export default function FilterItem({ item, onEdit, onDelete, sx }: TileFilterItemParams) {
    const { t } = useTranslation()

    return (
        <Box
            sx={{
                border: '1px solid #BBB',
                borderRadius: '3px',
                p: '3px 8px',
                display: 'flex',
                alignItems: 'center',
                wordBreak: 'break-word',
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: onEdit ? 'pointer' : 'default',
                }}
                onClick={onEdit ?? undefined}
            >
                <Typography
                    variant={'body2'}
                    sx={{
                        fontWeight: 'bold',
                        mr: '3px',
                        wordBreak: 'auto-phrase',
                    }}
                >
                    {t(item.field)}
                </Typography>
                <Typography variant={'body2'} sx={{ mr: '3px', minWidth: '20px' }}>
                    {item.operator.toUpperCase()}
                </Typography>
                <Typography
                    variant={'body2'}
                    sx={{
                        background: 'rgba(42, 157, 143, 0.2)',
                        borderRadius: '2px',
                        pr: '5px',
                        pl: '5px',
                        mr: '3px',
                    }}
                >
                    {Array.isArray(item.value) ? item.value.join(', ') : item.value}
                </Typography>
            </Box>
            {onDelete && (
                <Link
                    onClick={onDelete}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <HighlightOffIcon sx={{ fontSize: '15px' }} />
                </Link>
            )}
        </Box>
    )
}
