import Stack from '@mui/material/Stack'
import React from 'react'
import { useUserWorkbenchProjectAccess } from '../../workbenchSlice'
import { ProjectAccessType } from '../../../../model/model'
import { Alert, Button, CircularProgress, Tooltip, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import { useAppSelector } from '../../../../app/hooks'
import { selectComputeRuntimeConnected } from '../../../runtime/runtimeSlice'

interface ComputationFormParams {
    submit: () => void
    isSubmitting: boolean
    resetForm: () => void
    submitBtnLabel: string
    failedSpecificConditions?: boolean
    children: React.ReactNode | React.ReactNode[]
}

export default function ComputationFormWrapper({
    submitBtnLabel,
    submit,
    isSubmitting,
    resetForm,
    failedSpecificConditions,
    children,
}: ComputationFormParams) {
    const runtimeConnected = useAppSelector(selectComputeRuntimeConnected)
    const canUserContribute = useUserWorkbenchProjectAccess(
        ProjectAccessType.Contributor,
        ProjectAccessType.Administrator,
    )
    return (
        <Stack>
            {!canUserContribute ? (
                <Alert severity={'info'}>
                    You do not have <b>CONTRIBUTE</b> permission on this project, so you cannot launch new computations.
                </Alert>
            ) : (
                <>
                    {!failedSpecificConditions && (
                        <>
                            <Typography variant={'h6'} sx={{ mb: 1 }}>
                                New Computation
                            </Typography>
                            <Divider orientation='horizontal' sx={{ mb: 2 }} />
                            {children}
                            <Tooltip
                                title={
                                    !runtimeConnected
                                        ? 'You require an active standard runtime to launch this computation.'
                                        : ''
                                }
                                arrow
                            >
                                <div>
                                    <Button
                                        variant='contained'
                                        onClick={submit}
                                        sx={{ mb: 1, width: '100%' }}
                                        disabled={!runtimeConnected || !canUserContribute || isSubmitting}
                                    >
                                        {!isSubmitting ? submitBtnLabel : <CircularProgress size={20} />}
                                    </Button>
                                </div>
                            </Tooltip>
                            <Button variant='outlined' color={'warning'} onClick={resetForm} disabled={isSubmitting}>
                                Reset
                            </Button>
                        </>
                    )}
                </>
            )}
        </Stack>
    )
}
