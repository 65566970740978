import { Link } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { DataFrame } from 'danfojs'
import { useMemo, useState } from 'react'
import { useUserPermitted } from '../../../../features/auth/authSlice'
import CreateOrUpdateGeneListDialog from '../../../../features/genelist/CreateOrUpdateGeneListDialog'
import GeneDetailsDialog from '../../../gene/GeneDetailsDialog'
import {
    absGreaterThanOrEqualFilterOperator,
    absLowerThanFilterOperator,
    SingleGeneExtractorFactory,
} from '../../../../utils/grid'
import DataFrameGrid, { Render } from './DataFrameGrid'
import GridToolbarWithSaveGenes from './GridToolbarWithSaveGenes'

interface DataFrameGridWithGeneDetailsProps {
    df: DataFrame
    width: number
    height: number
    exportFileName: string
}

export default function DataFrameGridWithGeneDetails({
    df,
    width,
    height,
    exportFileName,
}: DataFrameGridWithGeneDetailsProps) {
    const [openCreateGeneListDialog, setOpenCreateGeneListDialog] = useState(false)
    const [openGeneSummaryDialog, setOpenGeneSummaryDialog] = useState(false)
    const [filteredGenes, setFilteredGenes] = useState<string[]>([])
    const [symbol, setSymbol] = useState('')

    const canUserRequestGeneDetails = useUserPermitted({ requestGeneDetails: true })

    const customRenderDataFrame = useMemo(() => {
        const geneSymbolRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    onClick={(event) => {
                        setSymbol(params.value)
                        setOpenGeneSummaryDialog(true)
                        event.stopPropagation()
                    }}
                >
                    {params.value}
                </Link>
            )
        }

        return (field: string): Render | undefined => {
            if (field === 'names') {
                return geneSymbolRenderer
            }
            return undefined
        }
    }, [])

    return (
        <>
            <DataFrameGrid
                df={df}
                slots={{
                    toolbar: GridToolbarWithSaveGenes,
                }}
                slotProps={{
                    toolbar: {
                        saveGenesCallback: (filteredGenes: string[]) => {
                            setFilteredGenes(filteredGenes)
                            setOpenCreateGeneListDialog(true)
                        },
                        extractGenesCallback: SingleGeneExtractorFactory('names'),
                        exportFileName: exportFileName,
                    },
                }}
                width={width}
                height={height}
                showBorder
                columnCustomFilterOperators={{
                    logfoldchanges: [absGreaterThanOrEqualFilterOperator, absLowerThanFilterOperator],
                }}
                {...(canUserRequestGeneDetails ? { customRenderer: customRenderDataFrame } : {})}
            />
            <CreateOrUpdateGeneListDialog
                openDialog={openCreateGeneListDialog}
                handleCloseDialog={() => {
                    setOpenCreateGeneListDialog(false)
                }}
                externalGenes={filteredGenes}
            />
            {canUserRequestGeneDetails && (
                <GeneDetailsDialog
                    openDialog={openGeneSummaryDialog}
                    handleCloseDialog={() => {
                        setOpenGeneSummaryDialog(false)
                    }}
                    symbol={symbol}
                />
            )}
        </>
    )
}
