import { Box } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks'
import { ComputeStatisticsPreview, ComputeStatisticsResult } from '../../../../../../model/analysisCommands'
import { ComputeStatistics } from '../../../../../../model/analysisComponents'
import { a11yProps } from '../../../../../../utils/grid'
import TabPanel from '../../../../../common/TabPanel'
import { receivedAppMessage } from '../../../../../dashboard/appMessageSlice'
import { selectAnalysisId, selectAnalysisWorkflow } from '../../../analysisSlice'
import ResultKeyTitle from '../../../common/ResultKeyTitle'
import { useLazyGetComputeStatisticsQuery } from '../../../results/analysisResultsApiSlice'
import Plot from 'react-plotly.js'
import ResultsLoadingOrMissing from '../../../common/ResultsLoadingOrMissing'
import DataFrameGrid from '../../../common/DataFrameGrid'
import { DataFrame, readCSV } from 'danfojs'
import { Data, Layout } from 'plotly.js'

export interface ComputeStatisticsComponentResultParams {
    preview: ComputeStatisticsPreview
    selectedKey: string
    setKey: (key: string | null) => void
    width: number
}

export default function ComputeStatisticsComponentResultDetails({
    preview,
    selectedKey,
    setKey,
    width,
}: ComputeStatisticsComponentResultParams) {
    const dispatch = useAppDispatch()
    const analysisId = useAppSelector(selectAnalysisId)
    const analysisWorkflow = useAppSelector(selectAnalysisWorkflow)

    const [correlationDataFrame, setCorrelationDataFrame] = useState<DataFrame | null>(null)
    const [anovaDataFrame, setAnovaDataFrame] = useState<DataFrame | null>(null)
    const [tukeySummaryDataFrame, setTukeySummaryDataFrame] = useState<DataFrame | null>(null)
    const [resultObject, setResultObject] = useState<ComputeStatisticsResult | null>(null)
    const [tab, setTab] = React.useState(0)

    const [getComputeStatisticsApi, { isFetching }] = useLazyGetComputeStatisticsQuery()

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue)
    }

    useEffect(() => {
        const fetchLoadData = async (analysisId: number, key: string) => {
            try {
                const result = await getComputeStatisticsApi({ analysisId, key }).unwrap()
                setResultObject(result)

                if (result.correlationDataUrl) {
                    readCSV(result.correlationDataUrl, {
                        skipEmptyLines: 'greedy',
                    })
                        .then((df) => {
                            if (!df) {
                                return
                            }
                            setCorrelationDataFrame(df)
                        })
                        .catch(() => {
                            dispatch(
                                receivedAppMessage({
                                    type: 'error',
                                    message: 'Error loading correlation data.',
                                }),
                            )
                        })
                }
                if (result.anovaDataUrl) {
                    readCSV(result.anovaDataUrl, {
                        skipEmptyLines: 'greedy',
                    })
                        .then((df) => {
                            if (!df) {
                                return
                            }
                            setAnovaDataFrame(df)
                        })
                        .catch(() => {
                            dispatch(
                                receivedAppMessage({
                                    type: 'error',
                                    message: 'Error loading ANOVA data.',
                                }),
                            )
                        })
                }
                if (result.tukeySummaryDataUrl) {
                    readCSV(result.tukeySummaryDataUrl, {
                        skipEmptyLines: 'greedy',
                    })
                        .then((df) => {
                            if (!df) {
                                return
                            }
                            setTukeySummaryDataFrame(df)
                        })
                        .catch(() => {
                            dispatch(
                                receivedAppMessage({
                                    type: 'error',
                                    message: 'Error loading Tukey summary data.',
                                }),
                            )
                        })
                }
            } catch {
                dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: 'Error loading plot data.',
                    }),
                )
            } finally {
                //TODO:
            }
        }
        if (!selectedKey || !analysisId) {
            return
        }

        void fetchLoadData(analysisId, selectedKey)
    }, [selectedKey, analysisId])

    const processedPlotData = useMemo(() => {
        if (!resultObject) {
            return [] as Data[]
        }
        return JSON.parse(JSON.stringify(resultObject?.interactivePlotJson.data)) as Data[]
    }, [resultObject])

    const processedPlotLayout = useMemo(() => {
        if (!resultObject) {
            return [] as Partial<Layout>
        }
        return JSON.parse(JSON.stringify(resultObject?.interactivePlotJson.layout)) as Partial<Layout>
    }, [resultObject])

    return (
        <>
            {analysisId && analysisWorkflow && (
                <>
                    <ResultKeyTitle
                        resultKey={selectedKey}
                        toOverview={() => {
                            setKey(null)
                        }}
                        name={preview.name}
                        execCommandId={preview.execCommandId}
                        analysisId={analysisId}
                        analysisWorkflow={analysisWorkflow}
                        component={ComputeStatistics}
                        sx={{
                            ml: 3,
                            mr: 2,
                            mt: 2,
                            '& .MuiTypography-root': {
                                fontSize: 18,
                                fontWeight: 'bold',
                            },
                        }}
                    />
                    <Box sx={{ pl: 2, pr: 2 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={handleTabChange}>
                                <Tab label='Plot' {...a11yProps(0)} />
                                {resultObject?.correlationDataUrl && <Tab label='Correlation Data' {...a11yProps(1)} />}
                                {resultObject?.olsSummaryData && <Tab label='OLS Summary Data' {...a11yProps(2)} />}
                                {resultObject?.anovaDataUrl && <Tab label='ANOVA Data' {...a11yProps(1)} />}
                                {resultObject?.tukeySummaryData && <Tab label='Tukey Summary' {...a11yProps(2)} />}
                                {resultObject?.chi2 && <Tab label='Chi-Square Test' {...a11yProps(1)} />}
                            </Tabs>
                        </Box>
                        <TabPanel index={0} value={tab} sx={{ pt: 2 }}>
                            {resultObject && resultObject.interactivePlotJson.data.length > 0 ? (
                                <>
                                    <Plot
                                        data={processedPlotData}
                                        layout={{
                                            ...processedPlotLayout,
                                            width: width - 500,
                                            height: Math.max(window.innerHeight - 200, 500),
                                        }}
                                        config={{
                                            scrollZoom: false,
                                            displaylogo: false,
                                            displayModeBar: true,
                                            modeBarButtonsToRemove: [
                                                'lasso2d',
                                                'select2d',
                                                'pan2d',
                                                'zoomIn2d',
                                                'zoomOut2d',
                                                'autoScale2d',
                                            ],
                                            toImageButtonOptions: {
                                                format: 'svg',
                                                filename: 'plot',
                                                scale: 2,
                                            },
                                        }}
                                    />
                                </>
                            ) : (
                                <ResultsLoadingOrMissing
                                    loading={isFetching}
                                    loadingMessage={'Loading interactive plot...'}
                                    missingMessage={
                                        'There was an error loading the interactive plot. Please refresh the page or contact support if the issue persists.'
                                    }
                                />
                            )}
                        </TabPanel>
                        {resultObject?.correlationDataUrl && correlationDataFrame && (
                            <TabPanel index={1} value={tab} sx={{ pt: 2 }}>
                                <DataFrameGrid
                                    df={correlationDataFrame}
                                    width={Math.max(width - 520, 550)}
                                    height={window.innerHeight - 200}
                                    showBorder
                                />
                            </TabPanel>
                        )}
                        {resultObject?.olsSummaryData && (
                            <TabPanel index={2} value={tab} sx={{ pt: 2 }}>
                                <pre>{resultObject.olsSummaryData}</pre>
                            </TabPanel>
                        )}
                        {resultObject?.anovaDataUrl && anovaDataFrame && (
                            <TabPanel index={1} value={tab} sx={{ pt: 2 }}>
                                <DataFrameGrid
                                    df={anovaDataFrame}
                                    width={Math.max(width - 520, 550)}
                                    height={window.innerHeight - 200}
                                    showBorder
                                />
                            </TabPanel>
                        )}
                        {resultObject?.tukeySummaryData && (
                            <TabPanel index={2} value={tab} sx={{ pt: 2 }}>
                                {resultObject?.tukeySummaryDataUrl ? (
                                    <>
                                        {tukeySummaryDataFrame ? (
                                            <DataFrameGrid
                                                df={tukeySummaryDataFrame}
                                                width={Math.max(width - 520, 550)}
                                                height={window.innerHeight - 200}
                                                showBorder
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <pre>{resultObject.tukeySummaryData}</pre>
                                )}
                            </TabPanel>
                        )}
                        {resultObject?.chi2 && (
                            <TabPanel index={1} value={tab} sx={{ pt: 2 }}>
                                <pre>
                                    {`         Chi-Square Test of Independence
==================================================
Statistic:                      ${resultObject.chi2['statistic']}
P-value:                        ${resultObject.chi2['pvalue']}
Degrees of freedom:             ${resultObject.chi2['dof']}
==================================================`}
                                </pre>
                            </TabPanel>
                        )}
                    </Box>
                </>
            )}
        </>
    )
}
