import {
    usePreviewComputeStatistics,
    useResultDatasetGenesSet,
    useResultObsColumnMapping,
    useResultObsFields,
} from '../../../results/analysisResultsSlice'
import ComputeStatisticsComponent from '../../common/compute_statistics/ComputeStatisticsComponent'

export default function ProteomicsAnalysisComputeStatistics() {
    const [obsColumnMapping] = useResultObsColumnMapping()
    const [computeStatisticsPreviews, isFetchingResults] = usePreviewComputeStatistics()
    const [obsFields] = useResultObsFields()
    const [geneSet] = useResultDatasetGenesSet()

    return (
        <ComputeStatisticsComponent
            computeStatisticsPreviews={computeStatisticsPreviews}
            isFetchingResults={isFetchingResults}
            obsColumnMapping={obsColumnMapping}
            obsFields={obsFields}
            geneSet={geneSet}
        />
    )
} 