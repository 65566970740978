import { apiSlice } from '../../app/apiSlice'
import { GeneInfo } from '../../model/model'

export const ErrGeneNotFound = 'gene not found'

export type GeneDetails = {
    details: GeneInfo
    redirectUrl: string
    error: string
}

export const geneApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGeneDetails: builder.query<GeneDetails, string>({
            query: (symbol) => ({
                url: '/private/gene/details',
                params: { symbol: symbol },
            }),
        }),
    }),
})

export const { useLazyGetGeneDetailsQuery } = geneApiSlice
