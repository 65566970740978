import { GridView, TableChart } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
    Alert,
    Box,
    IconButton,
    InputAdornment,
    Skeleton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import {
    DataGridPremium,
    GridCallbackDetails,
    GridColDef,
    GridRowModel,
    GridRowParams,
    MuiEvent,
} from '@mui/x-data-grid-premium'
import { debounce } from 'lodash'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import useStoredColumnModel from '../../../../hooks/useStoredColumnModel'
import useStoredFilterModel from '../../../../hooks/useStoredFilterModel'
import { Component } from '../../../../model/analysisComponents'
import ConfirmationDialog from '../../../common/ConfirmationDialog'
import { receivedAppMessage } from '../../../dashboard/appMessageSlice'
import { selectAnalysisId, selectAnalysisTitle } from '../analysisSlice'
import { useDeleteComputationResultBulkMutation } from '../results/analysisResultsApiSlice'
import { deleteComputationResult } from '../results/analysisResultsSlice'
import ResultsOverviewGridToolbar from './ResultsOverviewGridToolbar'
import { useTranslation } from 'react-i18next'
import { selectWorkbenchProjectTitle } from '../../workbenchSlice'

export enum ResultViewType {
    TILE = 'tile',
    GRID = 'grid',
}

export type ResultsOverviewProps<T> = {
    component?: Component
    resultsKey: string
    renderTile: (key: string) => React.ReactNode
    results?: Record<string, T>
    isFetchingResults: boolean
    quickSearchFilter: (keyword: string, k: string) => boolean
    bgColor?: string
    selectionModel: GridRowSelectionModel
    setSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
    gridColumnDefinitions: GridColDef[]
    renderRow: (key: string) => GridRowModel
    onRowClick?: (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => void
    overlapCallback?: () => void
}

export default function ResultsOverview<T>(props: ResultsOverviewProps<T>) {
    const { t } = useTranslation()
    const key = `result-view-type:${props.resultsKey}`
    const [keyList, setKeyList] = useState<string[]>([])
    const [searchText, setSearchText] = useState('')
    const [resultViewType, setResultViewType] = useState(() => {
        const value = localStorage.getItem(key)
        return value ? (value as ResultViewType) : ResultViewType.TILE
    })

    const dispatch = useAppDispatch()
    const analysisId = useAppSelector(selectAnalysisId)
    const analysisTitle = useAppSelector(selectAnalysisTitle)
    const projectTitle = useAppSelector(selectWorkbenchProjectTitle)

    const storageKey = useMemo(() => {
        return `workbench_analysis_results_${props.resultsKey}_${analysisId}`
    }, [analysisId, props.resultsKey])
    const [columnVisibilityModel, setColumnVisibilityModel] = useStoredColumnModel(storageKey, [])
    const [filterModel, setFilterModel] = useStoredFilterModel(storageKey, {
        items: [],
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })

    const [deleteBulkRequest, { isLoading: isDeleting }] = useDeleteComputationResultBulkMutation()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const gridRows = useMemo(() => {
        if (!props.results) {
            return []
        }
        return Object.keys(props.results).map((key) => props.renderRow(key))
    }, [props.results, props.renderRow])

    useEffect(() => {
        if (!props.results) {
            return
        }
        setKeyList(Object.keys(props.results))
    }, [props.results])

    useEffect(() => {
        search(searchText)
    }, [searchText])

    useEffect(() => {
        localStorage.setItem(key, resultViewType.toString())
    }, [resultViewType])

    const handleSearchTextChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    const search = useCallback(
        debounce((keyword: string) => {
            if (!props.results) {
                if (keyword.length > 0) {
                    setKeyList([])
                }
                return
            }
            if (keyword.length > 2) {
                const matchedKeys = Object.keys(props.results).filter((k) => {
                    return props.quickSearchFilter(keyword, k)
                })
                setKeyList(matchedKeys)
            } else if (keyword.length == 0) {
                setKeyList(Object.keys(props.results))
            }
        }, 300),
        [props.results],
    )

    const bgColor = useMemo(() => {
        return props.bgColor ?? '#FFF'
    }, [props.bgColor])

    const onBulkDelete = async () => {
        try {
            const keys = props.selectionModel.map((m) => m.toString())
            await deleteBulkRequest({
                analysisId: analysisId!,
                component: props.component!,
                keys,
            }).unwrap()

            for (const key of keys) {
                dispatch(
                    deleteComputationResult({
                        analysisId: analysisId!,
                        key: key,
                        component: props.component!,
                    }),
                )
            }

            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message:
                        keys.length > 1
                            ? `The ${keys.length} results were deleted successfully.`
                            : 'The result was deleted successfully.',
                }),
            )

            setDeleteDialogOpen(false)
        } catch (e) {
            dispatch(
                receivedAppMessage({
                    type: 'error',
                    message: 'Failed to delete the selected results.',
                }),
            )
        }
    }

    const renderTile = () =>
        props.isFetchingResults ? (
            <Box sx={{ p: 2, backgroundColor: bgColor }}>
                <Paper sx={{ width: 'calc(25% - 12px)', minWidth: '250px', textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                        <Skeleton animation='wave' variant='text' height={10} sx={{ width: '25%' }} />
                        <Skeleton animation='wave' variant='text' height={10} sx={{ width: '25%' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Skeleton animation='wave' variant='text' height={10} sx={{ width: '50%', mb: 2 }} />
                    </Box>
                    <Skeleton sx={{ height: 250 }} animation='wave' variant='rectangular' />
                </Paper>
            </Box>
        ) : (
            <Box sx={{ backgroundColor: bgColor }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'start',
                        width: '100%',
                        flexWrap: 'wrap',
                        p: 2,
                    }}
                    gap={2}
                >
                    {keyList.map((k, idx) => {
                        return (
                            <React.Fragment key={`results-overview-tile-${idx}`}>{props.renderTile(k)}</React.Fragment>
                        )
                    })}
                </Box>
            </Box>
        )

    const renderGrid = () => (
        <Box sx={{ height: 'calc(100vh - 150px)', pr: 2, pl: 2 }}>
            <DataGridPremium
                sx={{
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                }}
                loading={props.isFetchingResults || isDeleting}
                columns={props.gridColumnDefinitions}
                rows={gridRows}
                rowHeight={40}
                columnHeaderHeight={40}
                onRowClick={props.onRowClick}
                pagination={true}
                disableMultipleColumnsSorting
                disableRowSelectionOnClick
                checkboxSelection
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {
                    setFilterModel(newFilterModel)
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newColumnVisibilityModel) => {
                    setColumnVisibilityModel(newColumnVisibilityModel)
                }}
                onRowSelectionModelChange={(newSelectionModel) => {
                    props.setSelectionModel(newSelectionModel)
                }}
                rowSelectionModel={props.selectionModel}
                slots={{
                    toolbar: ResultsOverviewGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        resultsOverviewDeleteDisabled: props.selectionModel.length === 0 || analysisId === undefined,
                        resultsOverviewDeleteCallback: () => {
                            setDeleteDialogOpen(true)
                        },
                        exportFileName: [
                            'Panomics',
                            projectTitle ?? '',
                            analysisTitle ?? '',
                            props.component ? t(props.component) : '',
                            'results',
                        ].join('_'),
                        showOverlap: !!props.overlapCallback,
                        disableOverlap: props.selectionModel.length < 2 || analysisId === undefined,
                        overlapCallback: () => {
                            if (props.overlapCallback) {
                                props.overlapCallback()
                            }
                        },
                    },
                }}
            />

            <ConfirmationDialog
                title='Delete Confirmation'
                content={`Are you sure you want to permanently delete the selected ${props.selectionModel.length > 1 ? `${props.selectionModel.length} results?` : 'result?'}`}
                confirmButtonLabel='Delete'
                cancelButtonLabel='Cancel'
                openDialog={deleteDialogOpen}
                handleCloseDialog={() => {
                    setDeleteDialogOpen(false)
                }}
                handleConfirm={onBulkDelete}
                formSubmitted={isDeleting}
            />
        </Box>
    )

    const render = () => {
        switch (resultViewType) {
            case ResultViewType.TILE:
                return renderTile()
            case ResultViewType.GRID:
                return renderGrid()
            default:
                return null
        }
    }

    return (
        <Box sx={{ backgroundColor: bgColor, pt: 1, height: 'calc(100vh - 60px)' }}>
            <Toolbar variant='dense'>
                <ToggleButtonGroup
                    value={resultViewType}
                    exclusive
                    size='small'
                    onChange={(_, value) => {
                        if (value !== null) {
                            setResultViewType(value)
                        }
                    }}
                    aria-label='result view'
                >
                    <ToggleButton value={ResultViewType.TILE} aria-label='tile view'>
                        <GridView />
                    </ToggleButton>
                    <ToggleButton value={ResultViewType.GRID} aria-label='table view'>
                        <TableChart />
                    </ToggleButton>
                </ToggleButtonGroup>

                {resultViewType === ResultViewType.TILE && (
                    <TextField
                        value={searchText}
                        onChange={handleSearchTextChange}
                        sx={{ ml: 2, backgroundColor: '#FFF' }}
                        placeholder={'Quick search...'}
                        fullWidth
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                search(searchText)
                            }
                        }}
                        slotProps={{
                            input: {
                                endAdornment: searchText && (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='clear text'
                                            onClick={() => setSearchText('')}
                                            edge='end'
                                            sx={{ mr: 0.25 }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                )}
            </Toolbar>
            <Box sx={{ minHeight: 'calc(100vh - 124px)' }}>
                {props.isFetchingResults || keyList.length > 0 ? (
                    render()
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'calc(100vh - 124px)',
                            backgroundColor: bgColor,
                        }}
                    >
                        <Alert severity='info'>No data available.</Alert>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
