import { Box } from '@mui/material'
import React from 'react'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
    mt?: number
    sx?: SxProps<Theme>
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box role='tabpanel' hidden={value !== index} {...other}>
            <Box sx={{ mt: props.mt ? props.mt : 0 }}>{children}</Box>
        </Box>
    )
}
