import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
    receivedEnableGuestAccessChange,
    receivedOrganizationDomains,
    receivedSetGeneLinkOut,
    selectOrganization,
} from '../../auth/authSlice'
import StartupLoading from '../../auth/StartupLoading'
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import CopyToClipboardButton from '../../../components/CopyToClipboardButton'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useSetDomainsMutation, useSetGuestAccessMutation, useSetGeneLinkOutMutation } from './organizationApiSlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import config from '../../../app/config'
import { GeneLinkOut } from '../../../model/model'
import { useTranslation } from 'react-i18next'

export default function OrganizationSettings() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectOrganization)
    const [setGuestAccessApi] = useSetGuestAccessMutation()
    const [setDomainsApi, { isLoading: isSetDomainsLoading }] = useSetDomainsMutation()
    const [setGeneLinkOutApi, { isLoading: isSetGeneLinkOutLoading }] = useSetGeneLinkOutMutation()
    const [domains, setDomains] = useState<string[] | null>(null)
    const [domainsError, setDomainsError] = useState(false)
    const [geneLinkOut, setGeneLinkOut] = useState<GeneLinkOut | null>(null)
    const [geneLinkOutError, setGeneLinkOutError] = useState(false)
    const [geneLinkOutUrl, setGeneLinkOutUrl] = useState<string>('')
    const [geneLinkOutUrlError, setGeneLinkOutUrlError] = useState(false)

    useEffect(() => {
        if (!organization) {
            return
        }
        setDomains(organization.domains)
        setGeneLinkOut(organization.geneLinkOut)
        setGeneLinkOutUrl(organization.geneLinkOutURL)
    }, [organization])

    const guestAccessURL = useMemo(() => {
        if (!organization) {
            return ''
        }
        return `https://${organization.code}-${organization.secret}.panomics.bio`
    }, [organization])

    const handleEnableGuestAccessChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!organization) {
            return
        }
        dispatch(receivedEnableGuestAccessChange({ enableGuestAccess: event.target.checked }))
        try {
            await setGuestAccessApi({
                enableGuestAccess: event.target.checked,
            }).unwrap()
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: `Successfully ${event.target.checked ? 'enabled' : 'disabled'} guest access`,
                }),
            )
        } catch (e) {
            dispatch(receivedEnableGuestAccessChange({ enableGuestAccess: !event.target.checked }))
            throw e
        }
    }

    const validateDomains = () => {
        setDomainsError(false)
        let valid = true
        if (!domains || !domains.length) {
            setDomainsError(true)
            valid = false
        }
        return valid
    }

    const saveDomains = async () => {
        if (!validateDomains()) {
            return
        }
        await setDomainsApi({
            domains: domains ?? [],
        }).unwrap()
        dispatch(
            receivedOrganizationDomains({
                domains: domains ?? [],
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'The organization domains were successfully set.',
            }),
        )
    }

    const validateGeneLinkOut = () => {
        setGeneLinkOutError(false)
        setGeneLinkOutUrlError(false)
        let valid = true
        if (!geneLinkOut) {
            setGeneLinkOutError(true)
            valid = false
        }
        if (geneLinkOut === GeneLinkOut.GeneLinkOutExternalURL) {
            if (geneLinkOutUrl.trim() === '') {
                setGeneLinkOutUrlError(true)
                valid = false
            } else if (!geneLinkOutUrl.includes('{symbol}') && !geneLinkOutUrl.includes('{ensemblID}')) {
                setGeneLinkOutUrlError(true)
                valid = false
            }
        }
        return valid
    }

    const saveGeneLinkOut = async () => {
        if (!validateGeneLinkOut()) {
            return
        }
        await setGeneLinkOutApi({
            geneLinkOut: geneLinkOut ?? GeneLinkOut.GeneLinkOutNone,
            geneLinkOutUrl: geneLinkOutUrl,
        }).unwrap()
        dispatch(
            receivedSetGeneLinkOut({
                geneLinkOut: geneLinkOut ?? GeneLinkOut.GeneLinkOutNone,
                geneLinkOutUrl: geneLinkOutUrl,
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'The gene link out was successfully set.',
            }),
        )
    }

    return (
        <>
            {organization ? (
                <Box sx={{ p: 3, width: '100%' }}>
                    <Typography variant={'h6'} sx={{ mb: 2 }}>
                        Organization Settings
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {config.guestSystemEnabled && (
                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={organization.enableGuestAccess}
                                        onChange={handleEnableGuestAccessChange}
                                    />
                                }
                                label='Enable guest access'
                                sx={{ mr: 6 }}
                            />
                            {import.meta.env.VITE_PLATFORM !== 'dnanexus' && (
                                <>
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            fontStyle: 'italic',
                                            color: organization.enableGuestAccess ? '#264653' : '#848484',
                                        }}
                                    >
                                        {guestAccessURL}
                                    </Typography>
                                    <CopyToClipboardButton
                                        getText={() => guestAccessURL}
                                        disabled={!organization.enableGuestAccess}
                                    />
                                </>
                            )}
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                        <Tooltip title={'Type and hit Enter to add a new domain in the list.'}>
                            <Autocomplete
                                sx={{ width: '500px', mr: 1 }}
                                options={[]}
                                freeSolo
                                multiple
                                includeInputInList
                                value={domains ?? []}
                                onChange={(_: unknown, newValue: string[]) => {
                                    setDomains(newValue)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={'Domains'}
                                        type='text'
                                        fullWidth
                                        required
                                        error={domainsError}
                                    />
                                )}
                            />
                        </Tooltip>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                void saveDomains()
                            }}
                            disabled={isSetDomainsLoading}
                        >
                            {isSetDomainsLoading ? <CircularProgress size={20} /> : <>Save</>}
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', mt: 4, alignItems: 'center', gap: 1 }}>
                        <Autocomplete
                            sx={{ width: '500px' }}
                            options={[
                                GeneLinkOut.GeneLinkOutNone,
                                GeneLinkOut.GeneLinkOutPanomicsDetails,
                                GeneLinkOut.GeneLinkOutExternalURL,
                            ]}
                            getOptionLabel={(option) => t(`${option}`)}
                            value={geneLinkOut}
                            onChange={(_: unknown, newValue: GeneLinkOut | null) => {
                                setGeneLinkOut(newValue)
                                if (newValue !== GeneLinkOut.GeneLinkOutExternalURL) {
                                    setGeneLinkOutUrl('')
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={'Gene link-out type'}
                                    type='text'
                                    fullWidth
                                    required
                                    error={geneLinkOutError}
                                />
                            )}
                        />
                        {geneLinkOut === GeneLinkOut.GeneLinkOutExternalURL && (
                            <TextField
                                placeholder={'https://platform.opentargets.org/target/{ensemblID}'}
                                label={'External URL - use {symbol} or {ensemblID} as placeholders'}
                                value={geneLinkOutUrl}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setGeneLinkOutUrl(event.target.value)
                                }}
                                type='text'
                                sx={{ width: '500px' }}
                                required
                                error={geneLinkOutUrlError}
                            />
                        )}
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                void saveGeneLinkOut()
                            }}
                            disabled={isSetGeneLinkOutLoading}
                        >
                            {isSetGeneLinkOutLoading ? <CircularProgress size={20} /> : <>Save</>}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <StartupLoading />
            )}
        </>
    )
}
