import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import * as React from 'react'
import { CircularProgress } from '@mui/material'

export interface ConfirmationDialogParams {
    title: string
    content: string
    confirmButtonLabel: string
    cancelButtonLabel: string
    openDialog: boolean
    handleCloseDialog: () => void
    handleConfirm: () => void
    formSubmitted?: boolean
}

export default function ConfirmationDialog(props: ConfirmationDialogParams) {
    return (
        <Dialog open={props.openDialog} onClose={props.handleCloseDialog}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCloseDialog} color={'error'}>
                    {props.cancelButtonLabel}
                </Button>
                {!props.formSubmitted ? (
                    <Button onClick={props.handleConfirm} autoFocus>
                        {props.confirmButtonLabel}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
