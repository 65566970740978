import {
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    DialogContent,
    Box,
    Chip,
    Divider,
    Link,
    Stack,
    Paper,
    Alert,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
    DialogProps,
    DiseaseAssoc,
    DrugInteraction,
    GeneBasicInfo,
    GeneInfo,
    GeneInteraction,
    Pathway,
    Location,
} from '../../model/model'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useLazyGetGeneDetailsQuery } from './geneApiSlice'
import { useEffect, useMemo, useRef, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import SequentialLoader from '../common/SequentialLoader'
import { a11yProps } from '../../utils/grid'
import TabPanel from '../../features/common/TabPanel'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { TissueExpression } from '../../model/model'
import { DataGridPremium, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid-premium'
import Plot from 'react-plotly.js'
import { useWindowSize } from 'usehooks-ts'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import MedicationIcon from '@mui/icons-material/Medication'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

import { DataSet } from 'vis-data'
import { Network, Node, Edge, Options } from 'vis-network'
import ColoredChip from '../../components/ColoredChip'

interface GeneDetailsDialogProps extends DialogProps {
    symbol: string
}

interface CustomNode extends Node {
    fullName: string
    biotype: string
    functionDescriptions: string[]
    genomicLocation: Location
    pathways: Pathway[]
}

interface CustomEdge extends Edge {
    interactions: GeneInteraction[]
}

export default function GeneDetailsDialog({ openDialog, handleCloseDialog, symbol }: GeneDetailsDialogProps) {
    const [details, setDetails] = useState<GeneInfo | null>(null)
    const [error, setError] = useState<string>('')
    const [getGeneDetailsApi, { isFetching }] = useLazyGetGeneDetailsQuery()

    const close = useDeliberateDialogClose(() => {
        setDetails(null)
        setError('')
        handleCloseDialog()
    })

    useEffect(() => {
        if (!symbol) return
        if (!openDialog) return
        getGeneDetailsApi(symbol.toUpperCase())
            .unwrap()
            .then((res) => {
                if (res.redirectUrl !== '') {
                    window.open(res.redirectUrl, '_blank', 'noopener,noreferrer')
                    close()
                }
                setDetails(res.details)
                setError(res.error)
            })
    }, [symbol, openDialog])

    return (
        <Dialog open={openDialog} onClose={close} fullScreen>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                        Gene Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {isFetching ? (
                    <PanelPlaceholder>
                        <SequentialLoader
                            steps={[
                                'Loading gene functional information',
                                'Loading disease associations',
                                'Loading drug interactions',
                                'Loading molecular interactions',
                                'Loading pathways',
                            ]}
                            timeBetweenSteps={1000}
                        />
                    </PanelPlaceholder>
                ) : (
                    <>
                        {error ? (
                            <PanelPlaceholder>
                                <ErrorIcon color='error' sx={{ fontSize: 50 }} />
                                <Typography variant='h6'>The gene {symbol} was not found.</Typography>
                                <Typography>
                                    Please note that the gene summary only works for approved human gene symbols.
                                </Typography>
                            </PanelPlaceholder>
                        ) : (
                            details && <GeneDetailsPanel details={details} />
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}

const PanelPlaceholder = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 5,
                textAlign: 'center',
            }}
        >
            {children}
        </Box>
    )
}

const GeneDetailsPanel = ({ details }: { details: GeneInfo }) => {
    const [tab, setTab] = useState(0)

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue)
    }

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', gap: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '500px',
                    minWidth: '500px',
                    overflow: 'visible',
                }}
            >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Typography variant='h4'>{details.basicInfo.approvedSymbol}</Typography>
                    <ColoredChip label={details.basicInfo.biotype} size='small' />
                </Box>
                <Typography variant='h6'>{details.basicInfo.approvedName}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                        Chr{details.basicInfo.genomicLocation.chromosome}:{' '}
                        {details.basicInfo.genomicLocation.start.toLocaleString()} -{' '}
                        {details.basicInfo.genomicLocation.end.toLocaleString()}
                    </Typography>

                    {details.basicInfo.functionDescriptions?.length > 0 && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                Description
                            </Typography>
                            <Typography variant='body1'>{details.basicInfo.functionDescriptions[0]}</Typography>
                        </Box>
                    )}

                    {details.basicInfo.synonyms?.length > 0 && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                Also known as
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {details.basicInfo.synonyms.map((synonym, index) => (
                                    <Chip key={index} label={synonym} size='small' />
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box sx={{ flex: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleTabChange}>
                        <Tab label='Baseline Expression' {...a11yProps(0)} />
                        <Tab label='Disease Associations' {...a11yProps(1)} />
                        <Tab label='Drug Interactions' {...a11yProps(2)} />
                        <Tab label='Molecular Interactions' {...a11yProps(3)} />
                        <Tab label='Pathways' {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel index={0} value={tab} sx={{ pt: 2 }}>
                    <BaselineExpressionSection baselineExpression={details.expression ?? []} />
                </TabPanel>
                <TabPanel index={1} value={tab} sx={{ pt: 2 }}>
                    <DiseaseAssociationsSection
                        geneSymbol={details.basicInfo.approvedSymbol}
                        diseaseAssociations={details.diseases ?? []}
                    />
                </TabPanel>
                <TabPanel index={2} value={tab} sx={{ pt: 2 }}>
                    <DrugInteractionsSection
                        geneSymbol={details.basicInfo.approvedSymbol}
                        drugInteractions={details.drugs ?? []}
                    />
                </TabPanel>
                <TabPanel index={3} value={tab} sx={{ pt: 2 }}>
                    <MolecularInteractionsSection
                        gene={details.basicInfo}
                        interactions={details.interactions ?? []}
                        load={tab == 3}
                    />
                </TabPanel>
                <TabPanel index={4} value={tab} sx={{ pt: 2 }}>
                    <PathwaysSection
                        geneSymbol={details.basicInfo.approvedSymbol}
                        pathways={details.basicInfo.pathways}
                    />
                </TabPanel>
            </Box>
        </Box>
    )
}

const BaselineExpressionSection = ({ baselineExpression }: { baselineExpression: TissueExpression[] }) => {
    const processedData = useMemo(() => {
        return baselineExpression
            .filter((d) => d.rnaValue > 0)
            .slice(0, 30)
            .map((d) => ({
            ...d,
            proteinLevel: d.proteinLevel < 0 ? 0 : d.proteinLevel,
            }))
            .sort((a, b) => a.rnaValue - b.rnaValue)
    }, [baselineExpression])

    return (
        <Box sx={{ display: 'flex', height: 'calc(100vh - 180px)' }}>
            <Plot
                data={[
                    // -- RNA trace (left side, reversed) --
                    {
                        x: processedData.map((d) => d.rnaValue),
                        y: processedData.map((d) => d.tissue),
                        orientation: 'h',
                        type: 'bar',
                        name: 'RNA',
                        marker: { color: '#1f77b4' },
                        xaxis: 'x', // left subplot
                        yaxis: 'y', // shared y
                        hovertemplate: `<b>%{y}</b><br>` + `RNA: %{x}<extra></extra>`,
                    },
                    // -- Protein trace (right side, normal) --
                    {
                        x: processedData.map((d) => d.proteinLevel),
                        y: processedData.map((d) => d.tissue),
                        orientation: 'h',
                        type: 'bar',
                        name: 'Protein',
                        marker: { color: '#ff7f0e' },
                        xaxis: 'x2', // right subplot
                        yaxis: 'y', // same y
                        hovertemplate: `<b>%{y}</b><br>` + `Protein: %{x}<extra></extra>`,
                    },
                ]}
                layout={{
                    // 1 row, 2 columns
                    grid: {
                        rows: 1,
                        columns: 2,
                        pattern: 'independent', // each subplot has its own x-axis
                    },
                    // -- Left x-axis (RNA), reversed so bars go right->left --
                    xaxis: {
                        domain: [0, 0.5], // left half of the figure
                        autorange: 'reversed', // flip the direction
                        title: 'RNA Expression',
                        zeroline: false,
                    },
                    // -- Right x-axis (Protein), normal orientation --
                    xaxis2: {
                        domain: [0.5, 1], // right half of the figure
                        title: 'Protein Level',
                        zeroline: false,
                    },
                    // Shared y-axis for tissue names
                    yaxis: {
                        anchor: 'x', // anchor the y-axis to x-axis "x" (left subplot)
                        automargin: true,
                    },
                    margin: { l: 200, r: 20, t: 20, b: 50 },
                    showlegend: true,
                }}
                style={{ width: '100%' }}
                config={{ responsive: true }}
            />
        </Box>
    )
}

const DiseaseAssociationsSection = ({
    geneSymbol,
    diseaseAssociations,
}: {
    geneSymbol: string
    diseaseAssociations: DiseaseAssoc[]
}) => {
    const { width } = useWindowSize()

    const panelWidth = useMemo(() => {
        return width - 650
    }, [width])

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Disease',
                groupable: false,
                width: 250,
            },
            {
                field: 'description',
                headerName: 'Description',
                groupable: false,
                width: panelWidth - 500,
            },
            {
                field: 'score',
                headerName: 'Score',
                groupable: false,
                width: 125,
            },
            {
                field: 'evidenceCount',
                headerName: 'Evidence Count',
                groupable: false,
                width: 125,
            },
        ],
        [panelWidth],
    )

    return (
        <>
            {diseaseAssociations.length > 0 ? (
                <DataGridPremium
                    sx={{
                        border: '1px solid #ddd',
                        '& .MuiDataGrid-toolbarContainer': {
                            pt: 1,
                            pb: 1,
                            borderBottom: '1px solid #DDD',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            borderRadius: 0,
                        },
                        width: '100%',
                    }}
                    columns={columns}
                    rows={diseaseAssociations}
                    disableAggregation
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    pagination={true}
                    density='compact'
                />
            ) : (
                <Alert severity='info'>No disease associations found for {geneSymbol}.</Alert>
            )}
        </>
    )
}

const DrugInteractionsSection = ({
    geneSymbol,
    drugInteractions,
}: {
    geneSymbol: string
    drugInteractions: DrugInteraction[]
}) => {
    return (
        <>
            {drugInteractions.length > 0 ? (
                <Box
                    display='flex'
                    flexDirection='column'
                    gap={2}
                    sx={{ p: 0.5, maxHeight: 'calc(100vh - 170px)', overflow: 'auto' }}
                >
                    {drugInteractions.map((drug) => (
                        <Paper key={drug.drugId} elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='space-between'
                                spacing={1}
                                mb={1}
                            >
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <MedicationIcon color='primary' fontSize='large' />
                                    <Typography variant='h6' component='h2'>
                                        {drug.name}
                                    </Typography>

                                    {drug.approved ? (
                                        <Chip
                                            icon={<CheckCircleIcon />}
                                            label='Approved'
                                            color='success'
                                            variant='outlined'
                                        />
                                    ) : (
                                        <Chip
                                            icon={<RemoveCircleOutlineIcon />}
                                            label='Not Approved'
                                            color='warning'
                                            variant='outlined'
                                        />
                                    )}

                                    {drug.withdrawn && (
                                        <Chip
                                            icon={<HighlightOffIcon />}
                                            label='Withdrawn'
                                            color='error'
                                            variant='outlined'
                                        />
                                    )}

                                    <Chip label={`Phase: ${drug.phase}`} color='info' variant='outlined' />
                                    <Chip label={drug.type} color='primary' variant='outlined' />
                                </Stack>
                                <IconButton
                                    component='a'
                                    href={`https://platform.opentargets.org/drug/${drug.drugId}`}
                                    target='_blank'
                                    rel='noopener'
                                    aria-label='Open in OpenTargets Platform'
                                >
                                    <OpenInNewIcon />
                                </IconButton>
                            </Stack>
                            <Divider sx={{ mb: 2 }} />
                            <Typography variant='body2' color='text.secondary' mb={2}>
                                {drug.description}
                            </Typography>
                            {drug.tradeNames.length > 0 && (
                                <Stack direction='row' spacing={1} flexWrap='wrap'>
                                    {drug.tradeNames.map((tradeName) => (
                                        <Chip key={tradeName} label={tradeName} variant='outlined' sx={{ mb: 1 }} />
                                    ))}
                                </Stack>
                            )}
                        </Paper>
                    ))}
                </Box>
            ) : (
                <Alert severity='info'>No drug interactions found for {geneSymbol}.</Alert>
            )}
        </>
    )
}

interface MolecularInteractionsSectionProps {
    gene: GeneBasicInfo
    interactions: GeneInteraction[]
    load: boolean
}

export const MolecularInteractionsSection = ({ gene, interactions, load }: MolecularInteractionsSectionProps) => {
    const networkRef = useRef(null)

    const [selectedInfo, setSelectedInfo] = useState<
        | {
              type: 'node'
              symbol: string
              fullName: string
              biotype: string
              functionDescriptions: string[]
              genomicLocation: Location
              pathways: Pathway[]
          }
        | { type: 'edge'; interactions: GeneInteraction[] }
        | null
    >(null)

    function getShapeForBiotype(biotype: string): Node['shape'] {
        switch (biotype) {
            case 'protein_coding':
                return 'dot'
            case 'lncRNA':
                return 'triangle'
            case 'unprocessed_pseudogene':
            case 'processed_pseudogene':
                return 'diamond'
            default:
                return 'ellipse'
        }
    }

    useEffect(() => {
        if (!networkRef.current || !load) return

        const nodesArray: Node[] = [
            {
                id: gene.id,
                label: gene.approvedSymbol,
                shape: getShapeForBiotype(gene.biotype),
                title: `${gene.approvedSymbol}\n${gene.approvedName}`, // tooltip on hover
                // We store additional info that we'll retrieve on click
                biotype: gene.biotype,
                fullName: gene.approvedName,
                functionDescriptions: gene.functionDescriptions,
                genomicLocation: gene.genomicLocation,
                pathways: gene.pathways,
            } as Node, // cast Node to allow custom fields
        ]

        interface TargetGeneData {
            name: string
            biotype: string
            functionDescriptions: string[]
            genomicLocation: Location
            pathways: Pathway[]
            interactions: GeneInteraction[]
        }
        const targetGeneMap: Record<string, TargetGeneData> = {}

        interactions.forEach((interaction) => {
            const key = interaction.targetSymbol
            if (!targetGeneMap[key]) {
                targetGeneMap[key] = {
                    name: interaction.targetName,
                    biotype: interaction.biotype,
                    functionDescriptions: interaction.functionDescriptions,
                    genomicLocation: interaction.genomicLocation,
                    pathways: interaction.pathways,
                    interactions: [],
                }
            }
            targetGeneMap[key].interactions.push(interaction)
        })

        const targetNodes: Node[] = Object.entries(targetGeneMap).map(
            ([symbol, { name, biotype, functionDescriptions, genomicLocation, pathways }]) => ({
                id: symbol,
                label: symbol,
                shape: getShapeForBiotype(biotype),
                title: `${symbol} (${biotype})\n${name}`,
                biotype,
                fullName: name,
                functionDescriptions: functionDescriptions,
                genomicLocation: genomicLocation,
                pathways: pathways,
            }),
        )

        nodesArray.push(...targetNodes)

        const edgesArray: Edge[] = Object.entries(targetGeneMap).map(
            ([targetSymbol, { interactions: targetInteractions }]) => {
                // The thickness will be the count of interactions
                const width = targetInteractions.length

                return {
                    id: `${gene.id}-${targetSymbol}`,
                    from: gene.id,
                    to: targetSymbol,
                    width: width,
                    interactions: targetInteractions,
                } as Edge
            },
        )

        const nodesDataset = new DataSet<Node>(nodesArray)
        const edgesDataset = new DataSet<Edge>(edgesArray)

        const options: Options = {
            layout: {
                randomSeed: 1,
            },
            nodes: {
                font: {
                    size: 12,
                },
            },
            edges: {
                smooth: {
                    enabled: true,
                    type: 'dynamic',
                    forceDirection: 'none',
                    roundness: 0.5,
                },
                color: {
                    color: '#848484',
                    highlight: '#848484',
                },
            },
            physics: {
                enabled: false,
                stabilization: {
                    enabled: true,
                    iterations: 2000,
                    updateInterval: 10,
                },
            },
            interaction: {
                hover: true,
                dragNodes: true,
            },
        }

        const network = new Network(
            networkRef.current,
            {
                nodes: nodesDataset,
                edges: edgesDataset,
            },
            options,
        )

        network.on('click', (params) => {
            if (params.nodes.length > 0) {
                const nodeId = params.nodes[0]
                const nodeData = nodesDataset.get(nodeId)

                if (nodeData) {
                    const customNode = nodeData as unknown as CustomNode
                    setSelectedInfo({
                        type: 'node',
                        symbol: customNode.label || '',
                        fullName: customNode.fullName,
                        biotype: customNode.biotype,
                        functionDescriptions: customNode.functionDescriptions,
                        genomicLocation: customNode.genomicLocation,
                        pathways: customNode.pathways,
                    })
                }
            } else if (params.edges.length > 0) {
                const edgeId = params.edges[0]
                const edgeData = edgesDataset.get(edgeId)

                if (edgeData) {
                    const customEdge = edgeData as unknown as CustomEdge
                    setSelectedInfo({
                        type: 'edge',
                        interactions: customEdge.interactions,
                    })
                }
            } else {
                setSelectedInfo(null)
            }
        })

        network.stabilize()
    }, [gene, interactions, load])

    return (
        <Box sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 180px)', gap: 2 }}>
            <Box ref={networkRef} sx={{ width: '100%', height: 'calc(100vh - 200px)' }} />
            {selectedInfo && (
                <Box sx={{ flex: '0 0 350px', border: '1px solid #ddd', padding: '1rem', overflow: 'scroll' }}>
                    {selectedInfo.type === 'node' ? (
                        <Stack>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant='h6'>{selectedInfo.symbol}</Typography>
                                <ColoredChip label={selectedInfo.biotype} size='small' />
                            </Box>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                {selectedInfo.fullName}
                            </Typography>
                            <Typography variant='body2' sx={{ fontStyle: 'italic', mb: 2 }}>
                                Chr{selectedInfo.genomicLocation.chromosome}:{' '}
                                {selectedInfo.genomicLocation.start.toLocaleString()} -{' '}
                                {selectedInfo.genomicLocation.end.toLocaleString()}
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                    Description
                                </Typography>
                                <Typography variant='body2'>{selectedInfo.functionDescriptions.join(', ')}</Typography>
                            </Box>
                            {selectedInfo.pathways.length > 0 && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                        Pathways
                                    </Typography>
                                    {selectedInfo.pathways.map((pathway) => (
                                        <Link
                                            href={`https://reactome.org/PathwayBrowser/#/${pathway.id}&FLG=${selectedInfo.symbol}`}
                                            target='_blank'
                                            key={pathway.id}
                                        >
                                            <Typography variant='body2'>{pathway.name}</Typography>
                                        </Link>
                                    ))}
                                </Box>
                            )}
                        </Stack>
                    ) : (
                        <Stack>
                            <Typography variant='h6' sx={{ mb: 2 }}>
                                Interaction Evidence
                            </Typography>
                            <Typography variant='body1'>
                                <strong>Target:</strong> {selectedInfo.interactions[0].targetSymbol} (
                                {selectedInfo.interactions[0].targetName})
                            </Typography>
                            <Typography variant='body1'>
                                <strong>Score:</strong> {selectedInfo.interactions[0].score}
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                {selectedInfo.interactions.map((i, index) => (
                                    <ColoredChip label={i.detectionMethod} size='small' key={`interaction-${index}`} />
                                ))}
                            </Box>
                        </Stack>
                    )}
                </Box>
            )}
        </Box>
    )
}

const PathwaysSection = ({ geneSymbol, pathways }: { geneSymbol: string; pathways: Pathway[] }) => {
    const { width } = useWindowSize()

    const panelWidth = useMemo(() => {
        return width - 650
    }, [width])

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Pathway',
                groupable: false,
                width: panelWidth / 3,
            },
            {
                field: 'topLevelTerm',
                headerName: 'Top-level parent pathway',
                groupable: false,
                width: panelWidth / 3,
            },
            {
                field: 'targetAndPathway',
                headerName: 'View Target and Pathway',
                groupable: false,
                width: panelWidth / 3,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Link
                            href={`https://reactome.org/PathwayBrowser/#/${params.row.id}&FLG=${geneSymbol}`}
                            target='_blank'
                        >
                            Reactome Pathway Browser
                        </Link>
                    )
                },
            },
        ],
        [panelWidth],
    )

    return (
        <DataGridPremium
            sx={{
                border: '1px solid #ddd',
                '& .MuiDataGrid-toolbarContainer': {
                    pt: 1,
                    pb: 1,
                    borderBottom: '1px solid #DDD',
                },
                '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                },
                width: '100%',
            }}
            columns={columns}
            rows={pathways}
            disableAggregation
            disableColumnSelector
            disableMultipleColumnsSorting
            slots={{
                toolbar: GridToolbar,
            }}
            pagination={true}
            density='compact'
        />
    )
}
