import { Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import NoImage from '../../../../../../assets/images/noimage.png'
import ProgressiveImage from '../../../../../../components/ProgressiveImage'
import { ComputeStatisticsPreview } from '../../../../../../model/analysisCommands'
import { ResultTileProps } from '../../../common/ResultTileProps'

export default function ComputeStatisticsOverviewResultTile(props: ResultTileProps<ComputeStatisticsPreview>) {
    return (
        <Paper sx={{ width: 'calc(25% - 12px)', minWidth: '250px' }}>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    p: 2,
                }}
            >
                <Tooltip title={props.result.name} arrow>
                    <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                        {props.result.name.truncate(34)}
                    </Typography>
                </Tooltip>
            </Box>
            <ProgressiveImage
                lowResSrc={NoImage}
                highResSrc={props.result.previewPlotUrl}
                onClick={() => {
                    props.onClick(props.resultKey)
                }}
                sx={{
                    width: Math.max(props.width / 4, 250) - 12,
                    height: Math.max(props.width / 4, 250) - 50,
                    cursor: 'pointer',
                }}
            />
        </Paper>
    )
}
