import { GridFilterModel } from '@mui/x-data-grid-premium'
import { DataSlice } from '../../model/model'
import { apiSlice } from '../../app/apiSlice'

export type CreateDataSliceRequest = {
    name: string
    analysisId: number
    filter: GridFilterModel
}

export type CreateDataSliceResponse = {
    dataSlice: DataSlice
}

type GetDataSlicesRequest = {
    keyword: string
    analysisId: number
}

export type DataSliceAutocompleteResult = {
    id?: number
    name: string
    analysisId?: number
    filter?: GridFilterModel
    inputValue?: string
}

type DeleteDataSlicesRequest = {
    dataSliceIdList: number[]
}
type DeleteDataSlicesResponse = {
    dataSliceIdList: number[]
}

export const dataSliceApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDataSlices: builder.query<DataSliceAutocompleteResult[], GetDataSlicesRequest>({
            query: (req) => ({
                url: '/private/data-slice/autocomplete',
                params: { k: req.keyword, aid: req.analysisId },
            }),
        }),
        createDataSlice: builder.mutation<CreateDataSliceResponse, CreateDataSliceRequest>({
            query: (req) => ({
                url: '/private/data-slice/create',
                method: 'POST',
                body: req,
            }),
        }),
        listDataSlices: builder.query<DataSlice[], number>({
            query: (analysisId) => ({
                url: '/private/data-slice/list',
                params: { analysisId: analysisId },
            }),
        }),
        deleteDataSlices: builder.mutation<DeleteDataSlicesResponse, DeleteDataSlicesRequest>({
            query: (req) => ({
                url: '/private/data-slice/delete',
                method: 'DELETE',
                body: req,
            }),
        }),
    }),
})

export const {
    useLazyGetDataSlicesQuery,
    useCreateDataSliceMutation,
    useLazyListDataSlicesQuery,
    useDeleteDataSlicesMutation,
    endpoints: { getDataSlices, createDataSlice },
} = dataSliceApiSlice
