import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { SelectChangeEvent } from '@mui/material/Select'
import { GridFilterItem } from '@mui/x-data-grid-premium'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { ObsField } from '../../model/analysisCommands'
import { DialogProps, MetadataFieldType } from '../../model/model'
import CheckboxSelect from '../common/CheckboxSelect'
import OntologyForm from '../common/OntologyForm'

export interface CreateOrUpdateFilterItemParams extends DialogProps {
    item: GridFilterItem | null
    fields: ObsField[]
    onSubmit: (oldItem: GridFilterItem | null, newItem: GridFilterItem) => void
    inOperatorButtons?: boolean
}

export default function CreateOrUpdateFilterItemDialog({
    openDialog,
    handleCloseDialog,
    item,
    onSubmit,
    inOperatorButtons,
    fields,
}: CreateOrUpdateFilterItemParams) {
    const { t } = useTranslation()
    const [field, setField] = useState<ObsField | null>(null)
    const [fieldError, setFieldError] = useState(false)
    const [operator, setOperator] = useState('')
    const [operatorError, setOperatorError] = useState(false)
    const [value, setValue] = useState<string | null>()
    const [valueList, setValueList] = useState<string[]>([])
    const [valueError, setValueError] = useState(false)

    const valueAsList = field != null && operator === 'in'

    useEffect(() => {
        if (!fields || !openDialog) {
            return
        }
        if (!item) {
            resetValues()
            return
        }

        const foundField = fields.find((f) => f.field == item?.field)
        if (!foundField) {
            return
        }

        setField(foundField)
        setOperator(item.operator)

        if (item.operator === 'in') {
            setValueList(item.value)
        } else {
            setValue(item.value)
        }
    }, [item, fields, openDialog])

    const handleOperatorChange = (event: SelectChangeEvent) => {
        setOperator(event.target.value)
    }

    const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
        setValue(event.target.value)
    }

    const handleValueListChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event
        setValueList(typeof value === 'string' ? value.split(',') : value)
    }

    const markAllValueOptions = (mark: boolean) => {
        if (!field?.values) {
            return
        }

        if (mark) {
            setValueList(field.values)
        } else {
            setValueList([])
        }
    }

    const resetErrors = () => {
        setFieldError(false)
        setOperatorError(false)
        setValueError(false)
    }

    const resetValues = () => {
        setField(null)
        setOperator('')
        setValue('')
        setValueList([])
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!field) {
            setFieldError(true)
            valid = false
        }
        if (!operator) {
            setOperatorError(true)
            valid = false
        }
        if (!valueAsList && !value) {
            setValueError(true)
            valid = false
        }
        if (valueAsList && !valueList) {
            setValueError(true)
            valid = false
        }
        return valid
    }

    const submit = () => {
        if (!field || !validateForm()) {
            return
        }
        onSubmit(item, {
            field: field.field,
            operator: operator,
            value: valueAsList ? valueList : value,
        } as GridFilterItem)
        close()
    }

    useSubmitOnEnter(submit, openDialog)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        resetValues()
        resetErrors()
    })

    const inOptionWithValues = useMemo(() => {
        return field !== null && field?.values !== null && field.values.length > 0
    }, [field])

    const singleSelectFromValues = useMemo(() => {
        if (!field || !operator) {
            return false
        }
        return field.values.length > 0 && ['is', 'is not'].includes(operator)
    }, [field, operator])

    const checkboxSelectFromValues = useMemo(() => {
        if (!field || !operator) {
            return false
        }
        return field.values.length > 0 && operator == 'in'
    }, [field, operator])

    return (
        <Dialog open={openDialog} onClose={close}>
            <DialogTitle>{item ? 'Edit Filter Item' : 'Add Filter Item'}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '400px' }}>
                    <Autocomplete
                        sx={{ width: '100%', mt: 2 }}
                        options={fields ?? []}
                        getOptionLabel={(f) => t(f.field)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Field'
                                error={fieldError}
                                slotProps={{
                                    htmlInput: {
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    },
                                }}
                            />
                        )}
                        value={field}
                        onChange={(_, newValue: ObsField | null) => {
                            setField(newValue)
                            setOperator('')
                            setValue('')
                            setValueList([])
                        }}
                    />
                    <FormControl sx={{ width: '100%', mt: 2 }}>
                        <InputLabel required>{t('operator')}</InputLabel>
                        <Select
                            label={t('operator')}
                            value={operator}
                            onChange={handleOperatorChange}
                            error={operatorError}
                            required
                            fullWidth
                            variant={'outlined'}
                        >
                            {field?.type == MetadataFieldType.Numeric && [
                                <MenuItem key={'field-0'} value='='>
                                    =
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='!='>
                                    !=
                                </MenuItem>,
                                <MenuItem key={'field-2'} value='>'>
                                    &gt;
                                </MenuItem>,
                                <MenuItem key={'field-3'} value='>='>
                                    &gt;=
                                </MenuItem>,
                                <MenuItem key={'field-4'} value='<'>
                                    &lt;
                                </MenuItem>,
                                <MenuItem key={'field-5'} value='<='>
                                    &lt;=
                                </MenuItem>,
                            ]}
                            {field?.type == MetadataFieldType.String && [
                                <MenuItem key={'field-0'} value='is'>
                                    is
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='is not'>
                                    is not
                                </MenuItem>,
                                <MenuItem key={'field-2'} value='contains'>
                                    contains
                                </MenuItem>,
                                <MenuItem key={'field-3'} value='does not contain'>
                                    does not contain
                                </MenuItem>,
                            ]}
                            {field?.type == MetadataFieldType.List && [
                                <MenuItem key={'field-0'} value='is'>
                                    is
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='is not'>
                                    is not
                                </MenuItem>,
                            ]}
                            {field?.type == MetadataFieldType.Ontology && [
                                <MenuItem key={'field-0'} value='is'>
                                    is
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='is not'>
                                    is not
                                </MenuItem>,
                                <MenuItem key={'field-2'} value='contains'>
                                    contains
                                </MenuItem>,
                                <MenuItem key={'field-3'} value='does not contain'>
                                    does not contain
                                </MenuItem>,
                            ]}
                            {inOptionWithValues && [
                                <MenuItem key={'field-additional-in'} value='in'>
                                    in
                                </MenuItem>,
                            ]}
                        </Select>
                    </FormControl>
                    {field?.type == MetadataFieldType.Numeric && operator !== 'in' && (
                        <TextField
                            label={t('value')}
                            sx={{ mt: 2 }}
                            type='number'
                            value={value}
                            onChange={handleValueChange}
                            fullWidth
                            required
                        />
                    )}
                    {singleSelectFromValues ? (
                        <Autocomplete
                            sx={{ width: '100%', mt: 2 }}
                            options={field?.values ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Value'
                                    required
                                    slotProps={{
                                        htmlInput: {
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        },
                                    }}
                                />
                            )}
                            value={value || null}
                            onChange={(event, newValue: string | null) => {
                                setValue(newValue)
                            }}
                        />
                    ) : checkboxSelectFromValues ? (
                        <FormControl sx={{ mt: 2 }}>
                            <InputLabel required>{t('value')}</InputLabel>
                            <CheckboxSelect
                                label={t('value')}
                                values={valueList}
                                onChange={handleValueListChange}
                                error={valueError}
                                options={field?.values ?? []}
                            />
                            {inOperatorButtons && (
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                        <Button
                                            onClick={() => {
                                                markAllValueOptions(true)
                                            }}
                                        >
                                            Select all
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                markAllValueOptions(false)
                                            }}
                                        >
                                            Unselect all
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </FormControl>
                    ) : (
                        <>
                            {field?.type == MetadataFieldType.String && (
                                <TextField
                                    label={t('value')}
                                    sx={{ mt: 2 }}
                                    type='text'
                                    value={value}
                                    onChange={handleValueChange}
                                    fullWidth
                                    required
                                />
                            )}
                            {field?.type == MetadataFieldType.Ontology && operator && field?.ontologyId && (
                                <OntologyForm
                                    ontologyId={field.ontologyId}
                                    operator={operator}
                                    value={value ?? ''}
                                    setValue={(value) => setValue(value)}
                                />
                            )}
                            {field?.type == MetadataFieldType.List && (
                                <FormControl sx={{ mt: 2 }}>
                                    <InputLabel required>{t('value')}</InputLabel>
                                    {!valueAsList ? (
                                        <Select
                                            variant={'outlined'}
                                            label={t('value')}
                                            value={value ?? ''}
                                            onChange={handleValueChange}
                                            error={valueError}
                                            required
                                            fullWidth
                                        >
                                            {field?.options?.map((o, idx) => {
                                                return (
                                                    <MenuItem key={`field-${idx}`} value={o}>
                                                        {o}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    ) : (
                                        <CheckboxSelect
                                            label={t('value')}
                                            values={valueList}
                                            onChange={handleValueListChange}
                                            error={valueError}
                                            options={field?.options ?? []}
                                        />
                                    )}
                                </FormControl>
                            )}
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                <Button onClick={submit} autoFocus>
                    {t('submit')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
