import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { SxProps } from '@mui/system'

const ProgressiveImage = ({
    lowResSrc,
    highResSrc,
    onClick,
    sx,
}: {
    lowResSrc: string
    highResSrc: string
    onClick?: () => void
    sx?: SxProps
}) => {
    const [src, setSrc] = useState(lowResSrc)

    useEffect(() => {
        const img = new Image()
        img.src = highResSrc
        img.onload = () => setSrc(highResSrc)
    }, [highResSrc])

    return (
        <Box
            component='img'
            src={src}
            sx={sx}
            onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
        />
    )
}

export default ProgressiveImage
