import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import * as React from 'react'
import { DialogProps } from '../../../../model/model'
import { JsonViewer } from '@textea/json-viewer'
import { useLazyGetCommandResultsQuery } from '../workbenchAnalysisApiSlice'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { removeNullKeys } from '../../../../utils/misc'

export interface CommandParamsUsedParams extends DialogProps {
    commandId: number
}

export default function CommandParamsUsedDialog({ openDialog, handleCloseDialog, commandId }: CommandParamsUsedParams) {
    const [paramsUsed, setParamsUsed] = useState<Record<string, unknown> | null>(null)
    const [getCommandResultsApi] = useLazyGetCommandResultsQuery()

    useEffect(() => {
        getCommandResultsApi(commandId)
            .unwrap()
            .then((res) => {
                const jsonVal = removeNullKeys(JSON.parse(res.paramsUsed))
                setParamsUsed(jsonVal)
            })
    }, [commandId])

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
            <DialogTitle>Command Params</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <DialogContentText>
                    {paramsUsed ? (
                        <JsonViewer displayDataTypes={false} value={paramsUsed} />
                    ) : (
                        <CircularProgress size={30} />
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
