import { useTranslation } from 'react-i18next'
import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Dialog, DialogContent } from '@mui/material'
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { DialogProps } from '../../model/model'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'

export interface ClearAllFiltersDialogParams extends DialogProps {
    clearFilters: () => void
}

export default function ClearAllFiltersDialog({
    openDialog,
    handleCloseDialog,
    clearFilters,
}: ClearAllFiltersDialogParams) {
    const { t } = useTranslation()

    const submit = () => {
        clearFilters()
        handleCloseDialog()
    }

    useSubmitOnEnter(submit, openDialog)

    const close = useDeliberateDialogClose(handleCloseDialog)

    return (
        <Dialog open={openDialog} onClose={close}>
            <DialogTitle>Clear All Filters</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '400px' }}>
                    <Typography>Are you sure you want to clear all filters?</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                <Button onClick={submit} autoFocus>
                    {t('delete')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
