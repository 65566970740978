import { ScaleThreshold, scaleThreshold } from 'd3-scale'

export type ColorPalette = string[]

export const PLASMA_100: ColorPalette = [
    '#f0f921',
    '#f1f623',
    '#f1f325',
    '#f2ef27',
    '#f2ec29',
    '#f3e92b',
    '#f3e62d',
    '#f3e22f',
    '#f4df31',
    '#f4dc32',
    '#f4d934',
    '#f4d636',
    '#f4d237',
    '#f4cf39',
    '#f4cc3b',
    '#f4c93c',
    '#f4c63e',
    '#f4c23f',
    '#f4bf41',
    '#f3bc42',
    '#f3b944',
    '#f3b645',
    '#f2b247',
    '#f2af48',
    '#f1ac49',
    '#f1a94b',
    '#f0a64c',
    '#f0a34e',
    '#ef9f4f',
    '#ee9c50',
    '#ee9952',
    '#ed9653',
    '#ec9355',
    '#eb9056',
    '#ea8c57',
    '#e98959',
    '#e8865a',
    '#e7835c',
    '#e6805d',
    '#e57d5f',
    '#e47960',
    '#e37661',
    '#e17363',
    '#e07064',
    '#df6d66',
    '#dd6967',
    '#dc6669',
    '#da636a',
    '#d9606c',
    '#d75c6e',
    '#d6596f',
    '#d45671',
    '#d25272',
    '#d04f74',
    '#cf4c76',
    '#cd4877',
    '#ca4579',
    '#c7437c',
    '#c4407e',
    '#c13e80',
    '#be3c82',
    '#bb3983',
    '#b83785',
    '#b43587',
    '#b13288',
    '#ae308a',
    '#ab2e8b',
    '#a72c8c',
    '#a4298d',
    '#a0278e',
    '#9d258f',
    '#992390',
    '#952191',
    '#921f92',
    '#8e1d93',
    '#8a1b93',
    '#861994',
    '#831894',
    '#7f1694',
    '#7b1495',
    '#771395',
    '#731195',
    '#6e1095',
    '#6a0e95',
    '#660d94',
    '#620c94',
    '#5d0b94',
    '#590a93',
    '#540993',
    '#500992',
    '#4b0891',
    '#460891',
    '#410890',
    '#3b078f',
    '#35078e',
    '#2f078c',
    '#29078b',
    '#22088a',
    '#190888',
    '#0d0887',
]
export const VIRIDIS_100: ColorPalette = [
    '#fde725',
    '#f7e62a',
    '#f2e52e',
    '#ece332',
    '#e6e236',
    '#e1e139',
    '#dbe03c',
    '#d6de3f',
    '#d1dd42',
    '#ccdb45',
    '#c6da47',
    '#c1d84a',
    '#bcd74c',
    '#b7d54f',
    '#b2d351',
    '#add254',
    '#a9d056',
    '#a4ce58',
    '#9fcd5a',
    '#9bcb5c',
    '#96c95e',
    '#92c760',
    '#8dc562',
    '#89c464',
    '#84c266',
    '#80c068',
    '#7cbe69',
    '#78bc6b',
    '#73ba6d',
    '#6fb86f',
    '#6bb671',
    '#67b472',
    '#63b274',
    '#5fb076',
    '#5bae77',
    '#57ac79',
    '#53aa7a',
    '#4fa87c',
    '#4ba57e',
    '#47a37f',
    '#43a181',
    '#3f9f82',
    '#3b9d84',
    '#369b85',
    '#329887',
    '#2d9688',
    '#28948a',
    '#23928b',
    '#238f8c',
    '#268d8c',
    '#298a8c',
    '#2b888c',
    '#2d858c',
    '#2f838b',
    '#31808b',
    '#327d8b',
    '#347b8a',
    '#35788a',
    '#377689',
    '#387389',
    '#397188',
    '#3a6e88',
    '#3b6c87',
    '#3c6986',
    '#3d6786',
    '#3e6485',
    '#3f6284',
    '#3f5f83',
    '#405d82',
    '#415a81',
    '#415880',
    '#42557f',
    '#42537e',
    '#43507d',
    '#434e7c',
    '#444b7b',
    '#444979',
    '#444678',
    '#454477',
    '#454175',
    '#453e74',
    '#453c73',
    '#453971',
    '#463770',
    '#46346e',
    '#46316d',
    '#462f6b',
    '#462c69',
    '#462968',
    '#462666',
    '#462464',
    '#462163',
    '#461e61',
    '#451a5f',
    '#45175d',
    '#45135c',
    '#450f5a',
    '#450b58',
    '#440656',
    '#440154',
]

export const GLASBEY_30: ColorPalette = [
    '#d60000',
    '#8c3bff',
    '#018700',
    '#00acc6',
    '#97ff00',
    '#ff7ed1',
    '#6b004f',
    '#ffa52f',
    '#573b00',
    '#005659',
    '#0000dd',
    '#00fdcf',
    '#a17569',
    '#bcb6ff',
    '#95b577',
    '#bf03b8',
    '#645474',
    '#790000',
    '#0774d8',
    '#fdf490',
    '#004b00',
    '#8e7900',
    '#ff7266',
    '#edb8b8',
    '#5d7e66',
    '#9ae4ff',
    '#eb0077',
    '#a57bb8',
    '#5900a3',
    '#03c600',
]
export const GLASBEY_40: ColorPalette = [
    '#d60000',
    '#8c3bff',
    '#018700',
    '#00acc6',
    '#97ff00',
    '#ff7ed1',
    '#6b004f',
    '#ffa52f',
    '#573b00',
    '#005659',
    '#0000dd',
    '#00fdcf',
    '#a17569',
    '#bcb6ff',
    '#95b577',
    '#bf03b8',
    '#645474',
    '#790000',
    '#0774d8',
    '#fdf490',
    '#004b00',
    '#8e7900',
    '#ff7266',
    '#edb8b8',
    '#5d7e66',
    '#9ae4ff',
    '#eb0077',
    '#a57bb8',
    '#5900a3',
    '#03c600',
    '#9e4b00',
    '#9c3b4f',
    '#cac300',
    '#708297',
    '#00af89',
    '#8287ff',
    '#5d363b',
    '#380000',
    '#fdbfff',
    '#bde6bf',
]
export const GLASBEY_50: ColorPalette = [
    '#d60000',
    '#8c3bff',
    '#018700',
    '#00acc6',
    '#97ff00',
    '#ff7ed1',
    '#6b004f',
    '#ffa52f',
    '#573b00',
    '#005659',
    '#0000dd',
    '#00fdcf',
    '#a17569',
    '#bcb6ff',
    '#95b577',
    '#bf03b8',
    '#645474',
    '#790000',
    '#0774d8',
    '#fdf490',
    '#004b00',
    '#8e7900',
    '#ff7266',
    '#edb8b8',
    '#5d7e66',
    '#9ae4ff',
    '#eb0077',
    '#a57bb8',
    '#5900a3',
    '#03c600',
    '#9e4b00',
    '#9c3b4f',
    '#cac300',
    '#708297',
    '#00af89',
    '#8287ff',
    '#5d363b',
    '#380000',
    '#fdbfff',
    '#bde6bf',
    '#db6d01',
    '#93b8b5',
    '#e452ff',
    '#2f5282',
    '#c36690',
    '#54621f',
    '#c49e72',
    '#038287',
    '#69e680',
    '#802690',
]
export const GLASBEY_100: ColorPalette = [
    '#d60000',
    '#8c3bff',
    '#018700',
    '#00acc6',
    '#97ff00',
    '#ff7ed1',
    '#6b004f',
    '#ffa52f',
    '#573b00',
    '#005659',
    '#0000dd',
    '#00fdcf',
    '#a17569',
    '#bcb6ff',
    '#95b577',
    '#bf03b8',
    '#645474',
    '#790000',
    '#0774d8',
    '#fdf490',
    '#004b00',
    '#8e7900',
    '#ff7266',
    '#edb8b8',
    '#5d7e66',
    '#9ae4ff',
    '#eb0077',
    '#a57bb8',
    '#5900a3',
    '#03c600',
    '#9e4b00',
    '#9c3b4f',
    '#cac300',
    '#708297',
    '#00af89',
    '#8287ff',
    '#5d363b',
    '#380000',
    '#fdbfff',
    '#bde6bf',
    '#db6d01',
    '#93b8b5',
    '#e452ff',
    '#2f5282',
    '#c36690',
    '#54621f',
    '#c49e72',
    '#038287',
    '#69e680',
    '#802690',
    '#6db3ff',
    '#4d33ff',
    '#85a301',
    '#fd03ca',
    '#c1a5c4',
    '#c45646',
    '#75573d',
    '#016742',
    '#00d6d4',
    '#dadfff',
    '#f9ff00',
    '#6967af',
    '#c39700',
    '#e1cd9c',
    '#da95ff',
    '#ba03fd',
    '#915282',
    '#a00072',
    '#569a54',
    '#d38c8e',
    '#364426',
    '#97a5c3',
    '#8e8c5e',
    '#ff4600',
    '#c8fff9',
    '#ae6dff',
    '#6ecfa7',
    '#bfff8c',
    '#8c54b1',
    '#773618',
    '#ffa079',
    '#a8001f',
    '#ff1c44',
    '#5e1123',
    '#679793',
    '#ff5e93',
    '#4b6774',
    '#5291cc',
    '#aa7031',
    '#01cffd',
    '#00c36b',
    '#60345d',
    '#90d42f',
    '#bfd47c',
    '#5044a1',
    '#4d230c',
    '#7c5900',
    '#ffcd44',
    '#8201cf',
    '#4dfdff',
]

export function palette(min: number, max: number, cp: ColorPalette): ScaleThreshold<number, string, never> {
    const d = (max - min) / cp.length
    const domain = [] as number[]

    for (let i = 1; i < cp.length; i++) {
        domain.push(min + d * i)
    }

    return scaleThreshold<number, string>().range(cp).domain(domain)
}

export const generateRandomLightColor = (): string => {
    // Generate a random number between 200 and 255 for each color component
    const randomColorComponent = () => Math.floor(Math.random() * 56) + 200

    const r = randomColorComponent()
    const g = randomColorComponent()
    const b = randomColorComponent()

    // Convert each color component to a two-digit hexadecimal string
    const hex = (component: number) => component.toString(16).padStart(2, '0')

    // Combine the components into a hex color code
    return `#${hex(r)}${hex(g)}${hex(b)}`
}

export const stringToColor = (str: string): string => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    // Use the hash to generate different components of the color
    const hue = hash % 360 // 0-359 degrees
    const saturation = 65 + (hash % 20) // 65-84%
    const lightness = 45 + (hash % 15) // 45-59%

    // Adjust saturation and lightness based on hue to improve color distribution
    const adjustedSaturation = hue % 2 === 0 ? saturation - 10 : saturation
    const adjustedLightness = hash & 1 ? lightness - 5 : lightness

    return `hsl(${hue}, ${adjustedSaturation}%, ${adjustedLightness}%)`
}

// Function to calculate contrasting text color (black or white)
export const getContrastColor = (bgColor: string): string => {
    // If the color is in HSL format, convert it to RGB first
    if (bgColor.startsWith('hsl')) {
        const [h, s, l] = bgColor.match(/\d+/g)!.map(Number)
        const hslToRgb = (h: number, s: number, l: number): [number, number, number] => {
            s /= 100
            l /= 100
            const a = s * Math.min(l, 1 - l)
            const f = (n: number) => {
                const k = (n + h / 30) % 12
                return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
            }
            return [f(0), f(8), f(4)].map((x) => Math.round(x * 255)) as [number, number, number]
        }
        const [r, g, b] = hslToRgb(h, s, l)
        bgColor = `rgb(${r}, ${g}, ${b})`
    }

    // Extract RGB values
    const rgb = bgColor.match(/\d+/g)!.map(Number)
    const [r, g, b] = rgb

    // Use a simpler luminance formula that tends to work better for web colors
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

    // Use a threshold of 0.6 instead of 0.5 to be more conservative
    // This will choose white text more often for medium-dark colors
    return luminance > 0.6 ? 'black' : 'white'
}
