import {
    MicroarrayAnalysisResultData,
    RnaSeqAnalysisResultData,
    ScRnaSeqAnalysisResultData,
    ProteomicsAnalysisResultData,
} from './analysisCommands'
import { Component } from './analysisComponents'
import {
    Analysis,
    AnalysisWorkflow,
    ExecCommand,
    ExecCommandStatus,
    LightExecCommand,
    MicroarrayWorkflow,
    ProjectReadmeVersion,
    RnaSeqWorkflow,
    RuntimeDetails,
    ScRnaSeqWorkflow,
    Notification,
    ProteomicsWorkflow,
} from './model'

export type RequestMessage = {
    id: string
}

export type ResponseMessage = {
    id: string
    userId: number
    error: string

    // Asynchronous messages
    runtimeStatusInformation: RuntimeStatusInformation | null
    analysisCommandFinished: AnalysisCommandFinished | null
    execCommand: ExecCommand | null
    runningCommands: LightExecCommand[] | null
    projectReadmeVersion: ProjectReadmeVersion | null
    userNotification: Notification | null
    runtimeDeadlineApproaching: RuntimeDeadlineApproaching | null
}

export type RuntimeDeadlineApproaching = {
    instanceType: string
    timeLeftHours: number
}

export type RuntimeStatusInformation = {
    online: boolean
} & RuntimeDetails

export type AnalysisCommandFinished = {
    analysis: Analysis
    analysisId: number
    commandId: number
    component: Component
    status: ExecCommandStatus
    key: string | null

    scRnaSeqAnalysisResultData?: ScRnaSeqAnalysisResultData
    rnaSeqAnalysisResultData?: RnaSeqAnalysisResultData
    microarrayAnalysisResultData?: MicroarrayAnalysisResultData
    proteomicsAnalysisResultData?: ProteomicsAnalysisResultData
}

export function getAnalysisCommandFinishedResultData(analysisCommandFinished: AnalysisCommandFinished) {
    return (
        analysisCommandFinished.scRnaSeqAnalysisResultData ||
        analysisCommandFinished.rnaSeqAnalysisResultData ||
        analysisCommandFinished.microarrayAnalysisResultData ||
        analysisCommandFinished.proteomicsAnalysisResultData
    )
}

export function getAnalysisWorkflow(analysisCommandFinished: AnalysisCommandFinished): AnalysisWorkflow | undefined {
    if (analysisCommandFinished.rnaSeqAnalysisResultData) {
        return RnaSeqWorkflow
    }
    if (analysisCommandFinished.scRnaSeqAnalysisResultData) {
        return ScRnaSeqWorkflow
    }
    if (analysisCommandFinished.microarrayAnalysisResultData) {
        return MicroarrayWorkflow
    }
    if (analysisCommandFinished.proteomicsAnalysisResultData) {
        return ProteomicsWorkflow
    }
    return undefined
}
