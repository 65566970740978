import { Autocomplete, Box, CircularProgress, FormControlLabel, Switch, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../../../app/hooks'
import { ObsField } from '../../../../../../model/analysisCommands'
import { ComputeStatistics } from '../../../../../../model/analysisComponents'
import { ScRnaSeqWorkflow } from '../../../../../../model/model'
import { useComponentCommand } from '../../../hooks/useComponentCommand'
import DataSliceSelector from '../../../../../dataslice/DataSliceSelector'
import { selectAnalysisId, selectAnalysisNormalizedSamples, selectAnalysisWorkflow } from '../../../analysisSlice'
import { DataSliceAutocompleteResult } from '../../../../../dataslice/dataSliceApiSlice'
import { debounce } from 'lodash'
import { SxProps } from '@mui/system'
import ComputationFormWrapper from '../../../common/ComputationFormWrapper'

export interface ComputeStatisticsComponentFormParams {
    obsColumnMapping: Record<string, Record<string, string>>
    obsFields: ObsField[] | undefined
    geneSet: Set<string> | undefined
}

export default function ComputeStatisticsComponentForm({
    obsColumnMapping,
    obsFields,
    geneSet,
}: ComputeStatisticsComponentFormParams) {
    const [subset, setSubset] = useState<DataSliceAutocompleteResult | null>(null)
    const [v1, setV1] = useState<string | null>(null)
    const [v1Error, setV1Error] = useState(false)
    const [v2, setV2] = useState<string | null>(null)
    const [v2Error, setV2Error] = useState(false)
    const [color, setColor] = useState<string | null>(null)
    const [dropZeros, setDropZeros] = useState(false)

    const analysisId = useAppSelector(selectAnalysisId)
    const analysisWorkflow = useAppSelector(selectAnalysisWorkflow)
    const normalizedSamples = useAppSelector(selectAnalysisNormalizedSamples)

    const { submitCommand, isSubmitting } = useComponentCommand(ComputeStatistics)

    const handleDropZerosChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setDropZeros(checked)
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!v1) {
            setV1Error(true)
            valid = false
        }
        if (!v2) {
            setV2Error(true)
            valid = false
        }
        return valid
    }

    const submit = () => {
        if (!obsColumnMapping) {
            return
        }
        if (!validateForm()) {
            return
        }

        void submitCommand({
            subsetName: subset ? subset.name : null,
            subset: subset && subset.filter ? subset.filter : null,
            // @ts-expect-error null check in validateForm
            v1: v1,
            // @ts-expect-error null check in validateForm
            v2: v2,
            color: color,
            symbol: null,
            dropZeros: dropZeros,
        })
    }

    const resetErrors = () => {
        setV1Error(false)
        setV2Error(false)
    }

    const resetForm = () => {
        setV1Error(false)
        setV2Error(false)
        setSubset(null)
        setV1(null)
        setV2(null)
        setColor(null)
    }

    return (
        <ComputationFormWrapper
            submit={submit}
            isSubmitting={isSubmitting}
            submitBtnLabel={'Compute Relationship'}
            resetForm={resetForm}
        >
            <DataSliceSelector
                analysisId={analysisId}
                analysisWorkflow={analysisWorkflow}
                label='Subset'
                obsFields={obsFields}
                value={subset}
                setValue={(newValue) => setSubset(newValue)}
                required={false}
                error={false}
                sx={{ mb: 2 }}
            />
            {geneSet && obsFields && (
                <>
                    <VariableAutocomplete
                        genes={Array.from(geneSet)}
                        obsFields={obsFields.map((o) => o.field)}
                        variable={v1}
                        setVariable={setV1}
                        sx={{ width: '100%', mb: 2 }}
                        label={'Variable 1 (Gene or Observation)'}
                        required={true}
                        error={v1Error}
                    />
                    <VariableAutocomplete
                        genes={Array.from(geneSet)}
                        obsFields={obsFields.map((o) => o.field)}
                        variable={v2}
                        setVariable={setV2}
                        sx={{ width: '100%', mb: 2 }}
                        label={'Variable 2 (Gene or Observation)'}
                        required={true}
                        error={v2Error}
                    />
                    <VariableAutocomplete
                        genes={[]}
                        obsFields={obsFields.map((o) => o.field)}
                        variable={color}
                        setVariable={setColor}
                        sx={{ width: '100%', mb: 2 }}
                        label={'Color / Grouping'}
                        required={false}
                        error={false}
                    />
                </>
            )}
            {!normalizedSamples && (
                <Box sx={{ mb: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={dropZeros}
                                onChange={handleDropZerosChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={
                            <Typography variant={'body2'}>
                                Drop {analysisWorkflow == ScRnaSeqWorkflow ? 'cells' : 'samples'} with zero counts for
                                the selected gene(s).
                            </Typography>
                        }
                    />
                </Box>
            )}
        </ComputationFormWrapper>
    )
}

interface VariableAutocompleteParams {
    genes: string[]
    obsFields: string[]
    variable: string | null
    setVariable: React.Dispatch<React.SetStateAction<string | null>>
    sx?: SxProps
    label: string
    required: boolean
    error: boolean
}

const VariableAutocomplete = ({
    genes,
    obsFields,
    variable,
    setVariable,
    required,
    error,
    label,
    sx,
}: VariableAutocompleteParams) => {
    const [filteredOptions, setFilteredOptions] = useState<string[]>(obsFields)
    const [loading, setLoading] = useState(false)

    // Function to filter gene options client-side
    const filterOptions = (query: string) => {
        if (query.length == 0) {
            return obsFields
        }
        if (query.length < 3) {
            return
        }
        setLoading(true)
        const filteredGenes = genes.filter((option) => option.toLowerCase().includes(query.toLowerCase()))
        const filteredObs = obsFields.filter((option) => option.toLowerCase().includes(query.toLowerCase()))
        setFilteredOptions(filteredObs.concat(filteredGenes))
        setLoading(false)
    }

    // Debounce the filtering to improve performance
    const debouncedFilterOptions = useMemo(
        () => debounce(filterOptions, 300), // Adjust debounce time as needed
        [genes, obsFields],
    )

    // Handle input changes
    const handleInputChange = (_: React.SyntheticEvent, newInputValue: string) => {
        if (newInputValue) {
            debouncedFilterOptions(newInputValue)
        } else {
            setFilteredOptions(obsFields)
        }
    }

    const handleOptionChange = (_: React.SyntheticEvent, value: string | null) => {
        setVariable(value)
    }

    return (
        <Autocomplete
            value={variable || null}
            options={filteredOptions}
            loading={loading}
            onInputChange={handleInputChange}
            onChange={handleOptionChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant='outlined'
                    size='small'
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },
                    }}
                    required={required}
                    error={error}
                />
            )}
            sx={sx}
        />
    )
}
