import { GridFilterModel } from '@mui/x-data-grid-premium'
import { JwtPayload } from 'jwt-decode'
import { Font, LayoutAxis, Padding } from 'plotly.js'
import { Component } from './analysisComponents'

export type AnalysisStatus = string

export const InProgressAnalysis = 'in progress'
export const DoneAnalysis = 'done'
export const FailedAnalysis = 'failed'
export const PreparingAnalysis = 'preparing'

export type AnalysisWorkflow = string

export const ScRnaSeqWorkflow: AnalysisWorkflow = 'scRNA-seq/snRNA-seq'
export const RnaSeqWorkflow: AnalysisWorkflow = 'RNA-seq'
export const MicroarrayWorkflow: AnalysisWorkflow = 'Microarray'
export const ProteomicsWorkflow: AnalysisWorkflow = 'Proteomics'

export enum AnalysisSource {
    Samples = 'Samples',
    Asset = 'Asset',
}

export type Analysis = {
    id: number
    createdAt: string
    updatedAt: string
    user: User
    organization: Organization
    status: AnalysisStatus
    title: string
    projectId: number
    project: Project
    analysisWorkflow: AnalysisWorkflow
    published: boolean
    publishedAt?: string
    primaryGrouping?: MetadataField
    collection?: Collection
    normalizedSamples: boolean
    normalized: boolean
    umapComputed: boolean
    source: AnalysisSource
    exportedAsCellAtlas: boolean
    exportedAsCellAtlasAt: string
    cellAtlas: CellAtlas
}

export type User = {
    id: number
    createdAt: string
    updatedAt: string
    firstName: string
    lastName: string
    email: string
    phone: string
    organization: Organization
    avatar: string
    receiveMarketing: boolean
    receiveJobNotification: string
    role: Role
    active: boolean
    eulaAcceptedAt: string
}

export type License = {
    createdAt: string
    expiresAt: string
    type: string
    seats: number | null
}

export type Role = {
    id: number
    createdAt: string
    updatedAt: string
    name: string
    standard: boolean
} & PermissionSet

export type PermissionSet = {
    // Administrative
    manageUsers: boolean
    manageRoles: boolean
    manageMetadata: boolean
    manageGuests: boolean
    manageOrgSettings: boolean
    manageCollections: boolean
    manageGeneSetCollections: boolean
    manageCellAtlases: boolean

    // Sample Contributing
    importSamples: boolean
    editSampleMetadata: boolean
    manageProjectSamples: boolean

    // Project & Analyses
    implicitAdminOnProjects: boolean
    discoverProjects: boolean
    createProjects: boolean
    runAnalyses: boolean
    publishAnalyses: boolean
    downloadDatasets: boolean

    // Gene List
    manageAnyGeneList: boolean

    // User related
    editProfile: boolean
    viewBillingInfo: boolean
    addCreditsToOrg: boolean
    updateOrgBillingAccount: boolean
    useOrgBillingAccount: boolean
    manageApiKeys: boolean

    // Viewing
    browseSamples: boolean
    useOmicsBrowser: boolean

    // Gene Details
    requestGeneDetails: boolean
}

export const emptyPermissionSet = {
    manageUsers: false,
    manageRoles: false,
    manageMetadata: false,
    manageGuests: false,
    manageOrgSettings: false,
    manageCollections: false,
    manageGeneSetCollections: false,
    manageCellAtlases: false,
    importSamples: false,
    editSampleMetadata: false,
    manageProjectSamples: false,
    implicitAdminOnProjects: false,
    discoverProjects: false,
    createProjects: false,
    runAnalyses: false,
    publishAnalyses: false,
    downloadDatasets: false,
    manageAnyGeneList: false,
    editProfile: false,
    viewBillingInfo: false,
    addCreditsToOrg: false,
    updateOrgBillingAccount: false,
    useOrgBillingAccount: false,
    manageApiKeys: false,
    browseSamples: false,
    useOmicsBrowser: false,
    requestGeneDetails: false,
} as PermissionSet

export type Organization = {
    id: number
    createdAt: string
    updatedAt: string
    name: string
    code: string
    domains: string[]
    secret: string
    enableGuestAccess: boolean
    restricted: boolean
    restrictionReason: RestrictionReason
    geneLinkOut: GeneLinkOut
    geneLinkOutURL: string
}

export enum RestrictionReason {
    NegativeBalance = 'Negative Balance',
    Abuse = 'Abuse',
}

export type Patient = {
    id: number
    createdAt: string
    updatedAt: string
    externalId: string
}

export enum SampleFileMode {
    SingleSamplePerFile = 'single',
    MultipleSamplesPerFile = 'combined',
}

export type SampleType = string

export const scRNASeq: SampleType = 'scRNA-seq'
export const snRNASeq: SampleType = 'snRNA-seq'
export const RNASeq: SampleType = 'RNA-seq'
export const sRNASeq: SampleType = 'sRNA-seq'
export const Microarray: SampleType = 'Microarray'
export const Proteomics: SampleType = 'Proteomics'
export const VCF: SampleType = 'VCF'

export type SampleOrigin = string

export const Germline: SampleOrigin = 'germline'
export const Somatic: SampleOrigin = 'somatic'

export type SampleStatus = string

export const DraftSample: SampleStatus = 'draft'
export const LoadingSample: SampleStatus = 'loading'
export const FailedSample: SampleStatus = 'failed'
export const AnnotatingSample: SampleStatus = 'annotating'
export const SavingAnnotations: SampleStatus = 'saving'
export const ReadySample: SampleStatus = 'ready'

export type Sample = {
    id: number
    name: string
    type: SampleType
    createdAt: string
    user: User
    patient: Patient
    batchId: string
    createdBy: string
    status: SampleStatus
    metadata: Metadata[]
    cells: number | null
    organism: string
    normalized: boolean
    error: string
    inUse: boolean | null
}

export enum ProjectVisibility {
    Private = 'private',
    Discoverable = 'discoverable',
    Public = 'public',
}

export type Project = {
    id: number
    externalId: string
    PMID: string
    organization: Organization
    title: string
    description: string
    overallDesign: string
    organizationVisibility: ProjectVisibility
    tags: string[]
    createdAt: string
    updatedAt: string
    user: User
    readmeLockedBy: User | null
}

export type SampleProjectInfo = {
    id: number
    title: string
    externalId: string
    PMID: string
}

export type ExecCommandStatus = string

export const PendingCommand: ExecCommandStatus = 'pending'
export const InProgressCommand: ExecCommandStatus = 'in progress'
export const DoneCommand: ExecCommandStatus = 'done'
export const FailedCommand: ExecCommandStatus = 'failed'
export const TerminatedCommand: ExecCommandStatus = 'terminated'
export const CanceledCommand: ExecCommandStatus = 'canceled'
export const DeletedCommand: ExecCommandStatus = 'deleted'

export type CommandAvailabilityType = string

export const MultiCommand: CommandAvailabilityType = 'multi'
export const SingleCommand: CommandAvailabilityType = 'single'

export type ExecCommand = {
    id: number
    analysisId: number
    analysis?: Analysis
    createdAt?: string
    component?: string
    params: string
    status?: ExecCommandStatus
    user?: User
    jobLaunchedAt?: string
    jobStartedAt?: string
    jobEndedAt?: string
}

export type ExecCommandAudit = {
    id: number
    execCommandId: number
    analysisId: number
    analysis?: Analysis
    createdAt?: string
    component?: string
    params: string
    status?: ExecCommandStatus
    user?: User
    jobLaunchedAt?: string
    jobStartedAt?: string
    jobEndedAt?: string
    computationResult?: ComputationResult
}

export function execCommandAuditToCommandAudit(audit: ExecCommandAudit): ExecCommand | undefined {
    return audit.execCommandId ? { ...audit, id: audit.execCommandId } : undefined
}

export type CommandResult = {
    paramsUsed: string
}

export type OntologyClassValue = {
    label: string
}

export type OntologyClass = {
    ontologyId: number
    id: string
    value: OntologyClassValue
}

export const NCBITaxon = 'NCBITaxon'

export type ProjectAutocompleteResult = {
    id: number
    title: string
}

export type GeneListAutocompleteResult = {
    id: number
    name: string
    genes: string[]
}

export enum AssetType {
    File = 'file',
    Folder = 'folder',
}

export enum AssetStatus {
    Ready = 'ready',
    Uploading = 'uploading',
    Failed = 'failed',
}

export type Asset = {
    id: number
    fileName: string
    name: string
    mimeType: string
    createdAt: string
    updatedAt: string
    user: User
    size: number
    assetType: AssetType
    assetStatus: AssetStatus
}

export type AssetWithHierarchicalDetail = {
    id: number
    createdAt: string
    updatedAt: string
    user?: User
    organization?: Organization
    project?: Project
    prefix: string
    fileName: string
    size: number
    mimeType: string
    assetType: AssetType
    assetStatus: AssetStatus
    parentId?: number
    hasChildren: boolean
    hasFolderChildren: boolean
}

export enum MetadataFieldType {
    String = 'string',
    Numeric = 'numeric',
    List = 'list',
    Ontology = 'ontology',
}

export enum MetadataFieldTarget {
    Any = 'any',
    Sample = 'sample',
    Analysis = 'analysis',
}

export type Ontology = {
    id: number
    name: string
    standard: boolean
    createdAt: string
    updatedAt: string
    user: User
    description: string
    metadataFields: MetadataField[]
}

export const BaseImportMetadataFields = ['organism', 'sample_type', 'sample_origin']

export type MetadataField = {
    id: number
    name: string
    createdAt: string
    updatedAt: string
    index: number
    type: MetadataFieldType
    options: string[] | null
    ontology: Ontology | null
    user: User
    target: MetadataFieldTarget
    standard: boolean
    isSampleId: boolean
    isPatientId: boolean
    visibleByDefault: boolean
    description: string
}

export type MetadataFieldDetail = MetadataField & {
    inUse: boolean
}

export type Metadata = {
    value: string
    label: string
    field: MetadataField
}

export type AnalysisMetadata = Metadata

export type GeneListResult = {
    values: string[]
}

export type ScatterPlotData = {
    traces: ScatterPlotTrace[] | null
}

export type ScatterPlotTrace = {
    x: number[]
    y: number[]
    name: string
    text: string[]
    markerColor: string
}

export type UserUpdates = {
    firstName: string
    lastName: string
    email: string
    phone: string
    receiveMarketing: boolean
    receiveJobNotification: string
}

export enum RuntimeType {
    ComputeRuntime = 'compute',
    JupyterLabRuntime = 'jupyterlab',
    RStudioRuntime = 'rstudio',
}

export function runtimeTypeHasData(rt: RuntimeType): boolean {
    return rt === RuntimeType.JupyterLabRuntime || rt === RuntimeType.RStudioRuntime
}

export function displayRuntimeType(rt: RuntimeType): string {
    switch (rt) {
        case RuntimeType.ComputeRuntime:
            return 'Standard Compute'
        case RuntimeType.JupyterLabRuntime:
            return 'Jupyter Lab'
        case RuntimeType.RStudioRuntime:
            return 'RStudio'
    }
}

export type ComputeRuntimeDetails = Record<string, never>

export type JupyterLabRuntimeDetails = {
    url?: string
    password?: string
}

export type RStudioRuntimeDetails = {
    url?: string
    username?: string
    password?: string
}

export type RuntimeOption = {
    id: number
    proc: string
    instanceType: string
    gpus: number
    vcpus: number
    memoryGib: number
    gpuMemory: number
    runtimeType: RuntimeType
    volumeSize: number
    pricePerHourUsd: number
}

export type Collection = {
    id: number
    name: string
    groupings: MetadataField[]
    createdAt: string
    updatedAt: string
}

export type CollectionWrapper = {
    collection: Collection
    sampleCount: number
    sampleTypes: string
}

export type PanomicsJwtPayload = JwtPayload & {
    userId: string | number
}

export type GeneList = {
    description: string
    createdAt: string
    updatedAt: string
    user: User
} & GeneListBase

export type GeneListBase = {
    id: number
    name: string
    symbols: string[]
}

export type ComputationResult = {
    id: number
    createdAt: string
    updatedAt: string
    name: string
    key: string
    component: string
    analysisId: number
    execCommandId: number
}

export type AnalysisSummary = {
    barcodes: number
    variables: number
}

export type RenameComputationResultRequest = {
    analysisId: number
    component: Component
    key: string
    name: string
}

export type DeleteComputationResultRequest = {
    analysisId: number
    component: Component
    key: string
}

export type DeleteComputationResultBulkRequest = {
    analysisId: number
    component: Component
    keys: string[]
}

export type Organism = string
export type GenomeBuild = string

export type GenomeBuilds = {
    organisms: Organism[]
    buildsMap: Record<Organism, GenomeBuild[]>
}

export type FilesystemNode = {
    id: string
    name: string
    size?: number
    type: 'folder' | 'file'
    children?: FilesystemNode[]
}

export type ApiKey = {
    id: number
    createdAt: string
    expiresAt: string
    label: string
    clearTextKey: string
    viewablePartialKey: string
}

export type ExternalApiKey = {
    id: number
    createdAt: string
    expiresAt: string
    label: string
    provider: string
}

export type DashboardFilter = {
    id: number
    createdAt: string
    field: string
    operator: string
    value: string
}

export type DashboardSampleCompositionTile = {
    id: number
    createdAt: string
    groupBy: string
    colorBy: string
}

export type DashboardDatasetCompositionTile = {
    id: number
    createdAt: string
    groupBy: string
    colorBy: string
}

export type DashboardExpressionTile = {
    id: number
    analysisId: number
    createdAt: string
    groupBy: string
    colorBy: string
}

export type Dashboard = {
    id: number
    createdAt: string
    name: string
    filters: DashboardFilter[]
    logicalOperator: LogicalOperator
    signatureGenes: string[]
    signatureGeneList: GeneListBase | undefined
    signatureTabState: object
}

export type DatasetWrapper = {
    analysis: Analysis
    sampleCount: number
}

export type LogicalOperator = string

export const AND: LogicalOperator = 'AND'
export const OR: LogicalOperator = 'OR'

export type DataSlice = {
    id: number
    name: string
    createdAt: string
    analysisId: number
    filter: GridFilterModel
}

export interface SampleSliceDef {
    groupBy: string
    value: string
}

export interface DialogProps {
    openDialog: boolean
    handleCloseDialog: () => void
    onDone?: () => void
}

export type GeneExpressionTileSpec = {
    groupBy: string[]
    colorBy?: string | null
    genes: string[]
}

export type GeneExpressionTile = {
    id: number
    createdAt: string
    analysisId: number
    dataSlice: DataSlice | null
    spec: GeneExpressionTileSpec
    description: string
}

export enum AspectRatio {
    OneToOne = '1:1',
    TwoToOne = '2:1',
}

export type GetPlotDataResponse = {
    url: string
    type: string
}

export type GetGeneExpressionPlotDataRequest = GeneExpressionTileSpec & {
    analysisId: number
    dataSlice: DataSlice | undefined | null
}

export type RuntimeUserData = ComputeRuntimeDetails | JupyterLabRuntimeDetails | RStudioRuntimeDetails

export type RuntimeDetails = {
    id: number
    exists: boolean
    proc: string | null
    instanceType: string | null
    gpus: number | null
    vcpus: number | null
    memoryGib: number | null
    gpuMemoryGib: number | null
    createdAt: string | null
    durationHours: number | null
    runtimeType: RuntimeType
    userData?: RuntimeUserData | null
    volumeSize: number | null
}

export const MaxRuntimeDurationHours = 720

export type SampleSource = string

export const LocalSampleSource: SampleSource = 'local'
export const AwsSampleSource: SampleSource = 'aws'
export const DNAnexusSampleSource: SampleSource = 'dnanexus'

export type FileObject = {
    prefix: string
    name: string
    id: string
    size: number
}

export type FileObjectStructure = {
    prefix: string
    objects: FileObject[]
    childStructures: FileObjectStructure[]
}

export type RoleWithUserCount = Role & {
    userCount: number
}

export enum ProjectAccessType {
    Administrator = 'administrator',
    Contributor = 'contributor',
    Viewer = 'viewer',
}

export type ProjectAccess = {
    createdAt: string
    user: User
    userId: number
    projectId: number
    accessType: ProjectAccessType
}

export type GetWorkbenchResponse = {
    project: Project
    projectAccessList: ProjectAccess[]
    analysisList: Analysis[]
}

export type Guest = {
    id: number
    createdAt: string
    firstName: string
    lastName: string
    email: string
    institution: string
}

export type LightExecCommand = {
    id: number
    analysisId: number
    requestId: string
    status: ExecCommandStatus
    startedAt: string
    component: Component
}

export enum GeneSelectorInputType {
    GeneSymbols = 'Symbols',
    GeneList = 'GeneList',
    DifferentiallyExpressedGenes = 'DifferentiallyExpressedGenes',
    WGCNAModule = 'WGCNAModule',
}

export type GeneSetCollection = {
    id: number
    name: string
    description: string
    filename: string
    organism: Organism
    referenceSource: ReferenceGeneSetSource | null
    custom: boolean
    active: boolean
    createdAt: string
    updatedAt: string
    user: User
}

export type ProjectReadmeVersion = {
    id: number
    createdAt: string
    updatedAt: string
    project: Project
    content: string
    user: User
}

export enum NotificationType {
    AnalysisResult = 'analysis_result',
    ReadmeMention = 'readme_mention',
    DuplicateSampleNames = 'duplicate_sample_names',
    ProjectAccess = 'project_access',
    SampleMetadataUploadErrors = 'sample_metadata_upload_errors',
    GeneCoPerturbationResult = 'gene_coperturbation_result',
}

export type Notification = {
    id: number
    createdAt: string
    userId: number
    type: NotificationType
    read: boolean
    properties: Record<string, string>
    attachments: Record<string, string>
}

export type AnalysisResultNotificationProps = {
    projectTitle: string
    analysisId: number
    analysisTitle: string
    status: ExecCommandStatus
    workflow: AnalysisWorkflow
    computationResultKey: string
    component: Component
    error: string
}

export type GeneCoPerturbationResultNotificationProps = {
    targetGene: string
    geneSignatureIds: number[]
    pval: number
    logFoldChange: number
    raw: boolean
    coPerturbedGenesCsv: string
    error: boolean
}

export type ReadmeMentionNotificationProps = {
    projectId: number
    projectTitle: string
}

export type ProjectAccessNotificationProps = {
    projectId: number
    projectTitle: string
    accessType: ProjectAccessType
}

export type DuplicateSampleNamesNotificationProps = {
    duplicateSampleNames: string[]
}

export type SampleMetadataUploadErrorsNotificationProps = {
    errors: string[]
    projectId: number
}

export type PlotId = string

export const Embedding_UMAP = 'embedding_umap_config'
export const Embedding_PCA = 'embedding_pca_config'

export type PlotConfig = {
    title:
        | Partial<{
              text: string
              font: Partial<Font>
              xref: 'container' | 'paper'
              yref: 'container' | 'paper'
              x: number
              y: number
              xanchor: 'auto' | 'left' | 'center' | 'right'
              yanchor: 'auto' | 'top' | 'middle' | 'bottom'
              pad: Partial<Padding>
          }>
        | undefined
    xaxis: Partial<LayoutAxis>
    yaxis: Partial<LayoutAxis>
    toImageButtonOptions: Partial<{
        filename: string
        scale: number
        format: 'png' | 'svg' | 'jpeg' | 'webp'
        height: number
        width: number
    }>
}

export enum KcdfType {
    GAUSSIAN = 'Gaussian',
    POISSON = 'Poisson',
}

export enum RawCountsLocationType {
    Layer = 'layer',
    RawObject = 'raw_object',
    Main = 'main',
}

export enum CellAtlasStatus {
    Creating = 'creating',
    Ready = 'ready',
    Failed = 'failed',
    Retracted = 'retracted',
}

export type CellAtlas = {
    id: number
    createdAt: string
    user: User
    status: CellAtlasStatus
    error: string
    name: string
    description: number
    organism: Organism
    tissues: string[]
    conditions: string[]
    broadCellTypeObs: string
    intermediateCellTypeObs: string
    detailedCellTypeObs: string
    cells: number
    genes: number
    analysisId: number
}

export type SimilarityMetricType = 'pearson' | 'cosine'

export type UpSetModelEntry = {
    name: string
    sets: string[]
}

export type ReferenceGeneSetSource = {
    id: number
    name: string
    version: string
    source: string
}

export type ReferenceGeneSet = {
    id: number
    referenceId: string
    name: string
    sbgnDiagramJson: string
}

export type Address = {
    line1: string
    line2: string
    city: string
    state: string
    postalCode: string
    country: string
}

export type BillingAccount = {
    billingName: string
    billingEmail: string
    billingAddress: Address
    balance: number
}

export enum GeneLinkOut {
    GeneLinkOutNone = 'none',
    GeneLinkOutPanomicsDetails = 'panomics_details',
    GeneLinkOutExternalURL = 'external_url',
}

export type Location = {
    chromosome: string
    start: number
    end: number
    strand: number
}

export type Pathway = {
    id: string
    name: string
    topLevelTerm: string
}

export type GeneBasicInfo = {
    id: string
    approvedSymbol: string
    approvedName: string
    biotype: string
    genomicLocation: Location
    functionDescriptions: string[]
    synonyms: string[]
    pathways: Pathway[]
}

export type DiseaseAssoc = {
    id: string
    name: string
    description: string
    score: number
    evidenceCount: number
}

export type DrugInteraction = {
    drugId: string
    name: string
    description: string
    phase: number
    tradeNames: string[]
    type: string
    approved: boolean
    withdrawn: boolean
}

export type TissueExpression = {
    tissue: string
    rnaValue: number
    rnaUnit: string
    proteinLevel: number
}

export type GeneInteraction = {
    intactId: string
    targetSymbol: string
    targetName: string
    biotype: string
    genomicLocation: Location
    functionDescriptions: string[]
    pathways: Pathway[]
    score: number
    detectionMethod: string
    sourceDb: string
}

export type GeneInfo = {
    basicInfo: GeneBasicInfo
    diseases: DiseaseAssoc[] | null
    drugs: DrugInteraction[] | null
    expression: TissueExpression[] | null
    interactions: GeneInteraction[] | null
}
