import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { Command, CommandParams } from '../../../../model/analysisCommands'
import { Component } from '../../../../model/analysisComponents'
import { receivedAddRunningCommand, selectAnalysisId } from '../analysisSlice'
import { useSubmitCommandMutation } from '../workbenchAnalysisApiSlice'
import { LightExecCommand } from '../../../../model/model'
import { receivedAppMessage } from '../../../dashboard/appMessageSlice'

export function useComponentCommand<T extends Component>(componentType: T) {
    const dispatch = useAppDispatch()
    const [submitCommandApi] = useSubmitCommandMutation()

    const analysisId = useAppSelector(selectAnalysisId)

    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState(false)

    async function submit(params: CommandParams<T>, sampleIdList?: number[]) {
        if (!analysisId) {
            return
        }

        setSubmitting(true)
        setSubmitted(false)
        setError(false)

        try {
            const requestId = uuidv4()
            const lightExecCommand = await submitCommandApi({
                analysisId: analysisId,
                requestId: requestId,
                sampleIdList: sampleIdList,
                component: componentType,
                params: params,
            } as Command<T>).unwrap()

            dispatch(
                receivedAddRunningCommand({
                    lightExecCommand: lightExecCommand,
                }),
            )
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: 'Your job was successfully launched.',
                }),
            )
        } catch {
            setError(true)
        } finally {
            setSubmitting(false)
            setSubmitted(true)
        }
    }

    async function customSubmitWrap(promise: Promise<LightExecCommand>) {
        if (!analysisId) {
            return
        }

        setSubmitting(true)
        setSubmitted(false)
        setError(false)

        try {
            const lightExecCommand = await promise

            dispatch(
                receivedAddRunningCommand({
                    lightExecCommand: lightExecCommand,
                }),
            )
        } catch {
            setSubmitting(false)
            setSubmitted(true)
            setError(true)
        }
    }

    return {
        submitCommand: submit,
        customSubmitWrap,
        isSubmitting: submitting,
        isSubmitted: submitted,
        isError: error,
    }
}
