import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import BiotechIcon from '@mui/icons-material/Biotech'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import DifferenceIcon from '@mui/icons-material/Difference'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FlareIcon from '@mui/icons-material/Flare'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import SearchIcon from '@mui/icons-material/Search'
import SsidChartIcon from '@mui/icons-material/SsidChart'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import { Alert, CircularProgress, Link, MenuItem, Snackbar, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import * as React from 'react'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import NetworkIcon from '../../../assets/icons/Network'
import JobNotRunningIcon from '../../../assets/images/clock.png'
import JobRunningIcon from '../../../assets/images/energy.gif'
import {
    Analysis,
    AnalysisSource,
    InProgressCommand,
    LightExecCommand,
    MicroarrayWorkflow,
    RnaSeqWorkflow,
    ScRnaSeqWorkflow,
    ProteomicsWorkflow,
} from '../../../model/model'
import {
    clearAppMessage,
    receivedAppMessage,
    selectAppMessage,
    selectAppMessageOpen,
    selectAppMessageType,
    selectAppNavigationPath,
} from '../../dashboard/appMessageSlice'
import AnalysisNavBar from './AnalysisNavBar'
import {
    receivedAddRunningCommandList,
    receivedAnalysis,
    receivedRemoveRunningCommand,
    selectRunningCommands,
} from './analysisSlice'
import { receivedAnalysis as resultSliceReceivedAnalysis, resetResults } from './results/analysisResultsSlice'
import {
    useCancelCommandMutation,
    useGetAnalysisQuery,
    useLazyGetRunningComponentsQuery,
} from './workbenchAnalysisApiSlice'
import { useLazyGetWorkbenchQuery } from '../workbenchApiSlice'
import { receivedWorkbench } from '../workbenchSlice'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'

dayjs.extend(relativeTime)
dayjs.extend(utc)

export default function AnalysisDashboard({ children }: { children?: ReactNode }) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { aid } = useParams()
    if (!aid) {
        return <>...</>
    }
    const appMessageOpen = useAppSelector(selectAppMessageOpen)
    const appMessageType = useAppSelector(selectAppMessageType)
    const appMessage = useAppSelector(selectAppMessage)
    const appMessageNavigationPath = useAppSelector(selectAppNavigationPath)

    const [getWorkbench] = useLazyGetWorkbenchQuery()
    const { data: analysis } = useGetAnalysisQuery(parseInt(aid), { refetchOnMountOrArgChange: true })
    const [getRunningCommandsApi] = useLazyGetRunningComponentsQuery()

    useEffect(() => {
        if (analysis) {
            dispatch(
                receivedAnalysis({
                    analysis,
                }),
            )

            dispatch(
                resultSliceReceivedAnalysis({
                    analysis,
                }),
            )

            getRunningCommandsApi(analysis.id)
                .unwrap()
                .then((runningCommands) => {
                    dispatch(
                        receivedAddRunningCommandList({
                            analysisId: analysis.id,
                            components: runningCommands,
                        }),
                    )
                })
                .catch(() => {
                    dispatch(
                        receivedAppMessage({
                            type: 'error',
                            message: 'Error loading running commands.',
                        }),
                    )
                })

            getWorkbench(analysis.project.id)
                .unwrap()
                .then((workbench) => {
                    dispatch(
                        receivedWorkbench({
                            project: workbench.project,
                            projectAccessList: workbench.projectAccessList,
                            analysisList: workbench.analysisList,
                        }),
                    )
                })

            return () => {
                dispatch(resetResults())
            }
        }

        return () => {}
    }, [analysis, dispatch])

    const runningCommands = useAppSelector(selectRunningCommands)

    const tail = useMemo(() => {
        if (!analysis) {
            return ''
        }
        switch (analysis.analysisWorkflow) {
            case ScRnaSeqWorkflow:
                return window.location.pathname.replaceAll(`/analysis/scrnaseq/${analysis?.id}/`, '')
            case RnaSeqWorkflow:
                return window.location.pathname.replaceAll(`/analysis/rnaseq/${analysis?.id}/`, '')
            case MicroarrayWorkflow:
                return window.location.pathname.replaceAll(`/analysis/microarray/${analysis?.id}/`, '')
            case ProteomicsWorkflow:
                return window.location.pathname.replaceAll(`/analysis/prot/${analysis?.id}/`, '')
            default:
                return ''
        }
    }, [analysis?.analysisWorkflow, window.location.pathname])

    const getMenuItemColor = (page: string): string => {
        return tail == page ? '#2a9d8f!important' : '#264653'
    }

    return (
        <>
            {analysis != null ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', minWidth: '1280px' }}>
                    <CssBaseline />
                    <AnalysisNavBar analysis={analysis} />
                    <Box sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 60px)' }}>
                        <Box
                            sx={{
                                width: '120px',
                                borderRight: '1px solid #DDD',
                                height: '100%',
                                overflowX: 'hidden',
                                overflowY: 'auto',
                            }}
                        >
                            <Box sx={{ height: '100%' }}>
                                <Stack
                                    sx={{
                                        pr: 2,
                                        pl: 2,
                                        '& .MuiLink-root': {
                                            textDecoration: 'none',
                                            textAlign: 'center',
                                            mb: 2,
                                            pt: 2,
                                            color: '#264653',
                                        },
                                        '& .MuiLink-root:hover, & .MuiLink-root:hover .MuiTypography-root, & .MuiLink-root:hover .MuiSvgIcon-root':
                                            {
                                                color: '#2a9d8f',
                                            },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.6em',
                                            mb: '2px',
                                        },
                                        height: 'calc(100vh - 120px)',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    }}
                                >
                                    {analysis?.analysisWorkflow == ScRnaSeqWorkflow && (
                                        <ScRnaSeqAnalysisModules
                                            analysis={analysis}
                                            getMenuItemColor={getMenuItemColor}
                                        />
                                    )}
                                    {analysis?.analysisWorkflow == RnaSeqWorkflow && (
                                        <RnaSeqAnalysisModules
                                            analysis={analysis}
                                            getMenuItemColor={getMenuItemColor}
                                        />
                                    )}
                                    {analysis?.analysisWorkflow == MicroarrayWorkflow && (
                                        <MicroarrayAnalysisModules
                                            analysis={analysis}
                                            getMenuItemColor={getMenuItemColor}
                                        />
                                    )}
                                    {analysis?.analysisWorkflow == ProteomicsWorkflow && (
                                        <ProteomicsAnalysisModules
                                            analysis={analysis}
                                            getMenuItemColor={getMenuItemColor}
                                        />
                                    )}
                                </Stack>
                                <JobQueue runningCommands={runningCommands} />
                            </Box>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 120px)' }}>{children ? <>{children}</> : <Outlet />}</Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'calc(100vh - 112px)',
                    }}
                >
                    <Stack
                        sx={{
                            '& .MuiBox-root': {
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2,
                            },
                            '& .MuiBox-root .MuiTypography-root': {
                                ml: 1,
                            },
                        }}
                    >
                        <Box>
                            <CircularProgress size={20} />
                            <Typography>Downloading analysis data</Typography>
                        </Box>
                    </Stack>
                </Box>
            )}
            <Snackbar open={appMessageOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={() => {
                        dispatch(clearAppMessage())
                    }}
                    severity={appMessageType}
                    sx={{ width: '100%' }}
                >
                    {appMessage}
                    {appMessageNavigationPath && (
                        <Link
                            onClick={() => {
                                navigate(appMessageNavigationPath)
                                dispatch(clearAppMessage())
                            }}
                            sx={{ ml: 0.5 }}
                        >
                            View result
                        </Link>
                    )}
                </Alert>
            </Snackbar>
        </>
    )
}

const ScRnaSeqAnalysisModules = ({
    analysis,
    getMenuItemColor,
}: {
    analysis: Analysis
    getMenuItemColor: (v: string) => string
}) => {
    const navigate = useNavigate()

    return (
        <>
            <Link
                sx={{ pt: 0 }}
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/qc`)
                }}
            >
                <BiotechIcon sx={{ color: getMenuItemColor('qc') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('qc') }}>
                    Quality Control
                </Typography>
            </Link>
            <Divider />
            {analysis.source == AnalysisSource.Samples && (
                <>
                    <Link
                        onClick={() => {
                            navigate(`/analysis/scrnaseq/${analysis?.id}/gex`)
                        }}
                    >
                        <LeaderboardIcon sx={{ color: getMenuItemColor('gex') }} />
                        <Typography variant='body2' sx={{ color: getMenuItemColor('gex') }}>
                            Gene Expression
                        </Typography>
                    </Link>
                    <Divider />
                </>
            )}
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/compute-statistics`)
                }}
            >
                <AutoGraphIcon sx={{ color: getMenuItemColor('compute-statistics') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('compute-statistics') }}>
                    Compute Statistics
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/cell-explorer`)
                }}
            >
                <SearchIcon sx={{ color: getMenuItemColor('cell-explorer') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('cell-explorer') }}>
                    Cell Explorer
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/cell-composition`)
                }}
            >
                <StackedBarChartIcon sx={{ color: getMenuItemColor('cell-composition') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('cell-composition') }}>
                    Cell Composition
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/marker-genes`)
                }}
            >
                <BlurLinearIcon sx={{ color: getMenuItemColor('marker-genes') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('marker-genes') }}>
                    Marker Genes
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/dataslice`)
                }}
            >
                <DonutLargeIcon sx={{ color: getMenuItemColor('dataslice') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dataslice') }}>
                    Data Slices
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/deg`)
                }}
            >
                <DifferenceIcon sx={{ color: getMenuItemColor('deg') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('deg') }}>
                    Differential Expression
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/dap`)
                }}
            >
                <SsidChartIcon sx={{ color: getMenuItemColor('dap') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dap'), fontSize: '0.8em' }}>
                    Differential Pathway Activity
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/scrnaseq/${analysis?.id}/gsea`)
                }}
            >
                <FlareIcon sx={{ color: getMenuItemColor('gsea') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('gsea') }}>
                    Gene Set Enrichment
                </Typography>
            </Link>
            {/*<Divider />*/}
            {/*<Link className={'disabled'}>*/}
            {/*    <ForkRightIcon />*/}
            {/*    <Typography variant='body2'>Trajectory Inference</Typography>*/}
            {/*</Link>*/}
            {/*<Divider />*/}
            {/*<Link className={'disabled'}>*/}
            {/*    <SensorsIcon />*/}
            {/*    <Typography variant='body2'>Cell-Cell Communication</Typography>*/}
            {/*</Link>*/}
            {/*<Divider />*/}
            {/*<Link className={'disabled'}>*/}
            {/*    <WavesIcon />*/}
            {/*    <Typography variant='body2'>Perturbation Modeling</Typography>*/}
            {/*</Link>*/}
            {/*<Divider />*/}
        </>
    )
}

const RnaSeqAnalysisModules = ({
    analysis,
    getMenuItemColor,
}: {
    analysis: Analysis
    getMenuItemColor: (v: string) => string
}) => {
    const navigate = useNavigate()

    return (
        <>
            <Link
                sx={{ pt: 0 }}
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/qc`)
                }}
            >
                <BiotechIcon sx={{ color: getMenuItemColor('qc') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('qc') }}>
                    Quality Control
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/gex`)
                }}
            >
                <LeaderboardIcon sx={{ color: getMenuItemColor('gex') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('gex') }}>
                    Gene Expression
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/compute-statistics`)
                }}
            >
                <AutoGraphIcon sx={{ color: getMenuItemColor('compute-statistics') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('compute-statistics') }}>
                    Compute Statistics
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/sample-explorer`)
                }}
            >
                <SearchIcon sx={{ color: getMenuItemColor('sample-explorer') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('sample-explorer') }}>
                    Sample Explorer
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/marker-genes`)
                }}
            >
                <BlurLinearIcon sx={{ color: getMenuItemColor('marker-genes') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('marker-genes') }}>
                    Marker Genes
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/dataslice`)
                }}
            >
                <DonutLargeIcon sx={{ color: getMenuItemColor('dataslice') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dataslice') }}>
                    Data Slices
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/deg`)
                }}
            >
                <DifferenceIcon sx={{ color: getMenuItemColor('deg') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('deg') }}>
                    Differential Expression
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/dap`)
                }}
            >
                <SsidChartIcon sx={{ color: getMenuItemColor('dap') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dap'), fontSize: '0.8em' }}>
                    Differential Pathway Activity
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/wgcna`)
                }}
            >
                <NetworkIcon sx={{ color: getMenuItemColor('wgcna') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('wgcna') }}>
                    WGCNA
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/rnaseq/${analysis?.id}/gsea`)
                }}
            >
                <FlareIcon sx={{ color: getMenuItemColor('gsea') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('gsea') }}>
                    Gene Set Enrichment
                </Typography>
            </Link>
        </>
    )
}

const MicroarrayAnalysisModules = ({
    analysis,
    getMenuItemColor,
}: {
    analysis: Analysis
    getMenuItemColor: (v: string) => string
}) => {
    const navigate = useNavigate()

    return (
        <>
            <Link
                sx={{ pt: 0 }}
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/qc`)
                }}
            >
                <BiotechIcon sx={{ color: getMenuItemColor('qc') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('qc') }}>
                    Quality Control
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/gex`)
                }}
            >
                <LeaderboardIcon sx={{ color: getMenuItemColor('gex') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('gex') }}>
                    Gene Expression
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/compute-statistics`)
                }}
            >
                <AutoGraphIcon sx={{ color: getMenuItemColor('compute-statistics') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('compute-statistics') }}>
                    Compute Statistics
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/sample-explorer`)
                }}
            >
                <SearchIcon sx={{ color: getMenuItemColor('sample-explorer') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('sample-explorer') }}>
                    Sample Explorer
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/marker-genes`)
                }}
            >
                <BlurLinearIcon sx={{ color: getMenuItemColor('marker-genes') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('marker-genes') }}>
                    Marker Genes
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/dataslice`)
                }}
            >
                <DonutLargeIcon sx={{ color: getMenuItemColor('dataslice') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dataslice') }}>
                    Data Slices
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/deg`)
                }}
            >
                <DifferenceIcon sx={{ color: getMenuItemColor('deg') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('deg') }}>
                    Differential Expression
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/dap`)
                }}
            >
                <SsidChartIcon sx={{ color: getMenuItemColor('dap'), fontSize: '0.8em' }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dap') }}>
                    Differential Pathway Activity
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/wgcna`)
                }}
            >
                <NetworkIcon sx={{ color: getMenuItemColor('wgcna') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('wgcna') }}>
                    WGCNA
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/microarray/${analysis?.id}/gsea`)
                }}
            >
                <FlareIcon sx={{ color: getMenuItemColor('gsea') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('gsea') }}>
                    Gene Set Enrichment
                </Typography>
            </Link>
        </>
    )
}

const ProteomicsAnalysisModules = ({
    analysis,
    getMenuItemColor,
}: {
    analysis: Analysis
    getMenuItemColor: (v: string) => string
}) => {
    const navigate = useNavigate()

    return (
        <>
            <Link
                sx={{ pt: 0 }}
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/qc`)
                }}
            >
                <BiotechIcon sx={{ color: getMenuItemColor('qc') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('qc') }}>
                    Quality Control
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/pex`)
                }}
            >
                <LeaderboardIcon sx={{ color: getMenuItemColor('pex') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('pex') }}>
                    Protein Expression
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/compute-statistics`)
                }}
            >
                <AutoGraphIcon sx={{ color: getMenuItemColor('compute-statistics') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('compute-statistics') }}>
                    Compute Statistics
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/sample-explorer`)
                }}
            >
                <SearchIcon sx={{ color: getMenuItemColor('sample-explorer') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('sample-explorer') }}>
                    Sample Explorer
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/marker-proteins`)
                }}
            >
                <BlurLinearIcon sx={{ color: getMenuItemColor('marker-proteins') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('marker-proteins') }}>
                    Marker Proteins
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/dataslice`)
                }}
            >
                <DonutLargeIcon sx={{ color: getMenuItemColor('dataslice') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dataslice') }}>
                    Data Slices
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/dep`)
                }}
            >
                <DifferenceIcon sx={{ color: getMenuItemColor('dep') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dep') }}>
                    Differential Expression
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/dap`)
                }}
            >
                <SsidChartIcon sx={{ color: getMenuItemColor('dap') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('dap'), fontSize: '0.8em' }}>
                    Differential Pathway Activity
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/wgcna`)
                }}
            >
                <NetworkIcon sx={{ color: getMenuItemColor('wgcna') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('wgcna') }}>
                    WGCNA
                </Typography>
            </Link>
            <Divider />
            <Link
                onClick={() => {
                    navigate(`/analysis/prot/${analysis?.id}/gsea`)
                }}
            >
                <FlareIcon sx={{ color: getMenuItemColor('gsea') }} />
                <Typography variant='body2' sx={{ color: getMenuItemColor('gsea') }}>
                    Gene Set Enrichment
                </Typography>
            </Link>
        </>
    )
}

const JobQueue = ({ runningCommands }: { runningCommands: LightExecCommand[] }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const [cancelCommandApi] = useCancelCommandMutation()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const cancelCommand = async (analysisId: number, commandId: number) => {
        await cancelCommandApi(commandId).unwrap()
        dispatch(
            receivedRemoveRunningCommand({
                analysisId: analysisId,
                execCommandId: commandId,
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'Your job was successfully canceled.',
            }),
        )
    }

    return (
        <>
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: '1px solid #DDD',
                    height: '60px',
                    pl: 2,
                    pr: 2,
                    cursor: 'pointer',
                }}
            >
                {runningCommands.length > 0 ? (
                    <>
                        <Box component='img' src={JobRunningIcon} sx={{ width: 36, pr: 1 }} alt='' />
                    </>
                ) : (
                    <Box component='img' src={JobNotRunningIcon} sx={{ width: 32, pr: 1 }} alt='' />
                )}
                <Typography>
                    {runningCommands.length} job{runningCommands.length == 1 ? '' : 's'}
                </Typography>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id='job-queue'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                    dense: true,
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'auto',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 0.5,
                            ml: '112px',
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                left: -5,
                                bottom: 10,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                            '& .MuiMenuItem-root': {
                                flexDirection: 'column',
                                justifyContent: 'left',
                            },
                            '& .MuiMenuItem-root > .MuiBox-root': {
                                display: 'flex',
                                justifyContent: 'left',
                                width: '100%',
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {runningCommands.length > 0 ? (
                    runningCommands.map((c, i) => {
                        return (
                            <MenuItem key={`running-command-${i}`}>
                                <Box>
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: '10px',
                                            color: c.status == InProgressCommand ? '#2a9d8f' : '#f4a261',
                                            mr: 1,
                                        }}
                                    />
                                    <Typography>{t(c.component)}</Typography>
                                </Box>
                                {c.status == InProgressCommand ? (
                                    <Typography variant={'body2'} sx={{ width: '100%', pl: 2 }}>
                                        Running for:&nbsp;
                                        <span style={{ fontStyle: 'italic' }}>
                                            {dayjs(c.startedAt.toNumber()).fromNow(true)}
                                        </span>
                                    </Typography>
                                ) : (
                                    <Box sx={{ justifyContent: 'space-between' }}>
                                        <Typography
                                            variant={'body2'}
                                            sx={{ width: '100%', pl: 2, pr: 2, fontStyle: 'italic' }}
                                        >
                                            Queued
                                        </Typography>
                                        {i > 0 && (
                                            <Link
                                                color={'error'}
                                                onClick={(e) => {
                                                    void cancelCommand(c.analysisId, c.id)
                                                    e.stopPropagation()
                                                }}
                                            >
                                                Cancel
                                            </Link>
                                        )}
                                    </Box>
                                )}
                            </MenuItem>
                        )
                    })
                ) : (
                    <MenuItem>
                        <Typography>No running or queued jobs</Typography>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}
