import { apiSlice } from '../../../app/apiSlice'
import { GeneLinkOut } from '../../../model/model'

export interface SetGuestAccessRequest {
    enableGuestAccess: boolean
}

export interface SetDomainsRequest {
    domains: string[]
}

export interface SetGeneLinkOutRequest {
    geneLinkOut: GeneLinkOut
    geneLinkOutUrl: string
}

export const organizationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        setGuestAccess: builder.mutation<void, SetGuestAccessRequest>({
            query: (req) => ({
                url: '/private/admin/settings/guest-access',
                method: 'PUT',
                body: req,
            }),
        }),
        isGuestAccessEnabled: builder.query<boolean, string>({
            query: (secret) => ({
                url: '/check-guest-access',
                params: {
                    secret: secret,
                },
            }),
        }),
        setDomains: builder.mutation<void, SetDomainsRequest>({
            query: (req) => ({
                url: '/private/admin/settings/domains',
                method: 'PUT',
                body: req,
            }),
        }),
        setGeneLinkOut: builder.mutation<void, SetGeneLinkOutRequest>({
            query: (req) => ({
                url: '/private/admin/settings/gene-link-out',
                method: 'PUT',
                body: req,
            }),
        }),
    }),
})

export const {
    useSetGuestAccessMutation,
    useLazyIsGuestAccessEnabledQuery,
    useSetDomainsMutation,
    useSetGeneLinkOutMutation,
} = organizationApiSlice
