import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { resetStore } from '../../app/customActions'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { CookieTokenKey, StorageTokenKey } from '../../app/storageKeys'
import { RootState } from '../../app/store'
import { GeneLinkOut, PermissionSet, User, UserUpdates } from '../../model/model'
import { removeCookie } from '../../utils/misc'

type AuthState = {
    user: User | null
    token: string | null
    fetching: boolean
}

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, fetching: true } as AuthState,
    reducers: {
        setAuthentication: (
            state,
            {
                payload: { user, token },
            }: PayloadAction<{
                user: User
                token: string
            }>,
        ) => {
            localStorage.setItem(StorageTokenKey, token)
            state.token = token
            state.user = user
            state.fetching = false
        },
        setAuthenticating: (state) => {
            state.fetching = true
        },
        setFinishedAuthenticating: (state) => {
            state.fetching = false
        },
        clearAuthentication: (state) => {
            localStorage.removeItem(StorageTokenKey)
            removeCookie(CookieTokenKey)
            state.token = null
            state.user = null
            state.fetching = false
        },
        receivedUserUpdates: (state, { payload: { changes } }: PayloadAction<{ changes: Partial<UserUpdates> }>) => {
            if (state.user) {
                if (changes.firstName) {
                    state.user.firstName = changes.firstName
                }
                if (changes.lastName) {
                    state.user.lastName = changes.lastName
                }
                if (changes.receiveMarketing) {
                    state.user.receiveMarketing = changes.receiveMarketing
                }
                if (changes.receiveJobNotification) {
                    state.user.receiveJobNotification = changes.receiveJobNotification
                }
                if (changes.email) {
                    state.user.email = changes.email
                }
                if (changes.phone) {
                    state.user.phone = changes.phone
                }
            }
        },
        setAcceptedEULA: (state) => {
            if (state.user) {
                state.user.eulaAcceptedAt = new Date().toDateString()
            }
        },
        receivedEnableGuestAccessChange: (
            state,
            {
                payload: { enableGuestAccess },
            }: PayloadAction<{
                enableGuestAccess: boolean
            }>,
        ) => {
            if (state.user) {
                state.user.organization.enableGuestAccess = enableGuestAccess
            }
        },
        receivedOrganizationDomains: (
            state,
            {
                payload: { domains },
            }: PayloadAction<{
                domains: string[]
            }>,
        ) => {
            if (state.user) {
                state.user.organization.domains = domains
            }
        },
        receivedSetGeneLinkOut: (
            state,
            { payload: { geneLinkOut, geneLinkOutUrl } }: PayloadAction<{ geneLinkOut: GeneLinkOut; geneLinkOutUrl: string }>,
        ) => {
            if (state.user) {
                state.user.organization.geneLinkOut = geneLinkOut
                state.user.organization.geneLinkOutURL = geneLinkOutUrl
            }
        },
    },
})

export const {
    setAuthentication,
    setAuthenticating,
    setFinishedAuthenticating,
    receivedUserUpdates,
    clearAuthentication,
    setAcceptedEULA,
    receivedEnableGuestAccessChange,
    receivedOrganizationDomains,
    receivedSetGeneLinkOut,
} = authSlice.actions

export default authSlice.reducer

export const useLogout = () => {
    const dispatch = useAppDispatch()

    return useCallback(() => {
        // Order is important here. resetStore() empties slice stores and clearAuthentication() makes it not wait for it to be loaded again.
        localStorage.clear()
        dispatch(resetStore())
        dispatch(clearAuthentication())
    }, [dispatch])
}

export const selectIsAuthenticated = (state: RootState) => !!state.auth.user
export const selectIsAuthenticating = (state: RootState) => state.auth.fetching

export const selectToken = (state: RootState) => state.auth.token

export const selectInitials = (state: RootState) =>
    `${state.auth.user?.firstName.at(0)}${state.auth.user?.lastName.at(0)}`

export const selectUserId = (state: RootState) => state.auth.user?.id
export const selectUser = (state: RootState) => state.auth.user

export const selectFullName = (state: RootState) => `${state.auth.user?.firstName} ${state.auth.user?.lastName}`
export const selectOrganization = (state: RootState) => state.auth.user?.organization
export const selectOrganizationDomains = (state: RootState) => state.auth.user?.organization?.domains

export const selectUserRole = (state: RootState) => state.auth.user?.role

export const useUserPermitted = (requiredPermissions?: Partial<PermissionSet>, any?: boolean): boolean => {
    const userRole = useAppSelector(selectUserRole)
    if (!requiredPermissions) {
        return true
    }

    let allowed: boolean | undefined = true

    for (const key of Object.keys(requiredPermissions)) {
        const permissionKey = key as unknown as keyof PermissionSet
        if (permissionKey && requiredPermissions[permissionKey] === true) {
            const yes = userRole?.[permissionKey] === true

            if (any === true && yes) {
                return true
            }
            allowed = allowed && yes
        }
    }

    return allowed
}

export const selectAcceptedEULA = (state: RootState) => !!state.auth.user?.eulaAcceptedAt
