import { Alert, Box, CircularProgress, IconButton, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { GridFilterItem, GridLogicOperator } from '@mui/x-data-grid-premium'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../app/hooks'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { ObsField } from '../../model/analysisCommands'
import { DataSlice, DialogProps } from '../../model/model'
import { selectAnalysisId } from '../workbench/analysis/analysisSlice'
import { useCreateDataSliceMutation } from './dataSliceApiSlice'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import FilterManagementArea from '../filter/FilterManagementArea'
import Divider from '@mui/material/Divider'

interface CreateDataSliceParams extends DialogProps {
    name?: string
    obsFields: ObsField[] | undefined
    callback?: (dataSlice: DataSlice) => void
}

function CreateDataSliceDialog(props: CreateDataSliceParams) {
    const { t } = useTranslation()
    const analysisId = useAppSelector(selectAnalysisId)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [filterItems, setFilterItems] = useState<GridFilterItem[]>([])
    const [filterItemsError, setFilterItemsError] = useState(false)
    const [logicalOperator, setLogicalOperator] = useState<GridLogicOperator>(GridLogicOperator.And)
    const [createDataSliceApi, { isLoading }] = useCreateDataSliceMutation()

    useEffect(() => {
        setName(props.name ?? '')
    }, [props.name])

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!name) {
            setNameError(true)
            valid = false
        }
        if (!filterItems || filterItems.length == 0) {
            setFilterItemsError(true)
            valid = false
        }
        return valid
    }

    const resetErrors = () => {
        setNameError(false)
        setFilterItemsError(false)
    }

    const resetFormValues = () => {
        setName(props.name ?? '')
        setFilterItems([])
        setLogicalOperator(GridLogicOperator.And)
    }

    const submit = () => {
        if (!analysisId) {
            return
        }
        if (!validateForm()) {
            return
        }
        createDataSliceApi({
            name: name,
            analysisId: analysisId,
            filter: {
                items: filterItems,
                logicOperator: GridLogicOperator.And,
            },
        })
            .unwrap()
            .then((resp) => {
                if (props.callback) {
                    props.callback(resp.dataSlice)
                }
                close()
            })
    }

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(() => {
        resetFormValues()
        resetErrors()
        props.handleCloseDialog()
    })

    return (
        <Dialog
            open={props.openDialog}
            onClose={close}
            fullScreen
            PaperProps={{
                sx: {
                    width: '35%', // Set the width to 50%
                    minWidth: '400px',
                    maxWidth: '100%', // Override the default maxWidth
                    height: '100%', // Full height
                    m: 0, // Remove any default margins
                    position: 'fixed',
                    left: 0, // Align to the right, change to `left: 0` to align left
                    top: 0,
                },
            }}
        >
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                        {t('createDataSlice')}
                    </Typography>
                    {!isLoading ? (
                        <Button color='primary' variant={'contained'} onClick={submit}>
                            {t('save')}
                        </Button>
                    ) : (
                        <Button>
                            <CircularProgress size={20} />
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <DialogContent sx={{ width: '600px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%' }} component='form'>
                        <TextField
                            onChange={handleNameChange}
                            value={name}
                            label={t('name')}
                            error={nameError}
                            required
                            fullWidth
                            sx={{ mb: 2, mt: 1 }}
                        />
                    </Box>
                </Box>
                <Divider />
                <FilterManagementArea
                    fields={props.obsFields ?? []}
                    filterItems={filterItems}
                    setFilterItems={setFilterItems}
                    logicalOperator={logicalOperator}
                    setLogicalOperator={setLogicalOperator}
                    sx={{ mt: 2, mb: 2 }}
                />
                {filterItemsError && <Alert severity={'error'}>Please add at least 1 filter.</Alert>}
            </DialogContent>
        </Dialog>
    )
}

export default CreateDataSliceDialog
